export default () => ({
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'fas fa-chart-bar',
    },
    {
      name: 'Carica Documenti',
      url: '/upload',
      icon: 'fas fa-upload',
    },
    {
      name: 'Profilo',
      url: '/profile',
      icon: 'far fa-user',
    },
    {
      name: 'Gestione Documenti',
      url: '/documents',
      icon: 'far fa-copy',
    },
    {
      name: 'Gestione Pratiche',
      url: '/pratiche/',
      icon: 'fas fa-folder',
    },
    {
      url: '/reset-pass',
      icon: 'fas fa-key',
      name: 'Modifica Password',
    },
  ].filter(_ => _),
});
