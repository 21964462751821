// eslint-disable-next-line import/no-extraneous-dependencies
import { put, takeLatest, call, select } from '@redux-saga/core/effects';
import { toast } from 'react-toastify';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import { actions, selectors } from './slice';
import * as api from './api';

function* fetchProcedure(action) {
  try {
    const data = yield call(api.fetchProcedure, action.payload);
    console.log(data);
    yield put(actions.setProcedure(data));
  } catch (e) {
    yield put(actions.errorLoadingProcedure());
  }
}

function* fetchMessages(action) {
  try {
    const data = yield call(api.fetchMessages, action.payload);
    yield put(actions.setMessages(data));
    yield call(api.setMessagesRead, action.payload);
  } catch (e) {
    yield put(actions.errorLoadingProcedure());
  }
}

function* fetchDocuments(action) {
  const { data: procedure, categoryId } = yield select(selectors.selectState);
  const extra = {};
  if (action.type === actions.documentsChangePage.type) {
    extra.pageStart = action.payload;
  }
  try {
    const data = yield call(
      api.fetchDocuments,
      {
        categoryId,
        id: procedure.Id,
      },
      extra,
    );
    yield put(actions.setDocuments(data));
    const cat = yield call(api.getCategory, categoryId);
    yield put(actions.setCategoryName(cat.Name));
  } catch (e) {
    yield put(actions.errorLoadingProcedure());
  }
}

function* searchDocuments(action) {
  if (
    action.type === actions.searchChangeDateSelector.type &&
    !action.payload.dateStart &&
    !action.payload.dateEnd
  ) {
    return;
  }
  const { data: procedure, categoryId, searchDocuments: state } = yield select(
    selectors.selectState,
  );
  const extra = {};

  if (action.type === actions.searchChangePage.type) {
    extra.pageStart = action.payload;
  }

  const { filters } = state;
  if (filters.dateSelector.dateEnd && filters.dateSelector.dateStart) {
    extra.startDate = filters.dateSelector.dateStart;
    extra.endDate = filters.dateSelector.dateEnd;
  }

  try {
    const data = yield call(api.searchDocuments, {
      userId: procedure.UserId,
      unboundToPracticeId: procedure.Id,
      categoryId,
      status: 'Complete',
      ...extra,
    });
    yield put(actions.setSearchDocs(data));
    const cat = yield call(api.getCategory, categoryId);
    yield put(actions.setCategoryName(cat.Name));
  } catch (e) {
    console.log(e);
    toast.error('Errore nel cercare i documenti');
  }
}

function* sendMessage(action) {
  const id = yield select(selectors.selectId);
  try {
    yield call(api.sendMessage, id, action.payload);
  } catch (e) {
    toast.error('Il messaggio non è stato inviato');
  }
}

function* associate(action) {
  const { id, categoryId } = yield select(selectors.selectState);
  try {
    yield call(api.associate, id, action.payload);
    yield put(actions.searchRemoveDoc(action.payload.DocumentId));
    yield put(actions.searchDocs());
    yield put(
      actions.loadDocuments({
        id,
        categoryId,
      }),
    );
    toast.success('Documento aggiunto alla pratica');
  } catch (e) {
    console.log(e);
    toast.error("Errore nell'aggiunta del documento alla pratica");
  }
}

function* deletePlaceholder(action) {
  const id = yield select(selectors.selectId);
  try {
    yield call(api.removeDocument, id, action.payload);
    toast.success('Documento rimosso dalla pratica');
  } catch (e) {
    console.log(e);
    toast.error("Errore nel rimuovere l'associazione del documento alla pratica");
  }
}

function mapAndOmit(array) {
  return array.map(o => omit(o, ['Id', 'UserDetailsId']));
}

function* updateProcedure(action) {
  const id = yield select(selectors.selectId);

  const data = pick(action.payload, [
    'DichAnnoScorso',
    'DichAnniPrecedenti',
    'DichCongiunta',
    'DelegaCUInps',
    'Irpef8mille',
    'Irpef5mille',
    'Irpef2mille',
    'Irpef5milleCF',
    'Irpef2milleCF',
  ]);
  const userData = omit(action.payload.UserDetails, [
    'Children',
    'Lands',
    'FamilyMembers',
    'Homes',
    'AmmSocialiType',
  ]);

  if (data.Irpef8mille) {
    data.Irpef8mille = data.Irpef8mille.Id;
  }
  if (data.Irpef5mille) {
    data.Irpef5mille = data.Irpef5mille.Id;
  }
  if (data.Irpef2mille) {
    data.Irpef2mille = data.Irpef2mille.Id;
  }

  if (action.payload.UserDetails) {
    userData.AmmSocialiTypeId = userData.AmmSocialiType ? userData.AmmSocialiType.Id : null;
    userData.CadastralCode = action.payload.UserCity && action.payload.UserCity.CadastralCode;
  }

  console.log(userData, action.payload);

  try {
    yield call(api.updateProcedure, id, data);
    if (action.payload.UserDetails) {
      yield call(api.updateUserdetailsGroup, 'webapp', userData);
      if (action.payload.UserDetails.Children) {
        yield call(
          api.updateUserdetailsGroup,
          'children',
          mapAndOmit(action.payload.UserDetails.Children),
        );
      }
      if (action.payload.UserDetails.Lands) {
        yield call(
          api.updateUserdetailsGroup,
          'lands',
          mapAndOmit(action.payload.UserDetails.Lands),
        );
      }
      if (action.payload.UserDetails.FamilyMembers) {
        yield call(
          api.updateUserdetailsGroup,
          'familymembers',
          mapAndOmit(action.payload.UserDetails.FamilyMembers),
        );
      }
      if (action.payload.UserDetails.Homes) {
        yield call(
          api.updateUserdetailsGroup,
          'homes',
          mapAndOmit(action.payload.UserDetails.Homes),
        );
      }
    }
    yield put(actions.loadProcedure(id));
    toast.success('Pratica modificata');
  } catch (e) {
    console.log(e);
    toast.error('Errore nel salvataggio della pratica');
  }
}

export default function* procedureSaga() {
  yield takeLatest(actions.loadProcedure.type, fetchProcedure);
  yield takeLatest(actions.loadMessages.type, fetchMessages);
  yield takeLatest(actions.loadDocuments.type, fetchDocuments);
  yield takeLatest(actions.documentsChangePage.type, fetchDocuments);
  yield takeLatest(actions.sendMessage.type, sendMessage);
  yield takeLatest(actions.searchDocs.type, searchDocuments);
  yield takeLatest(actions.searchChangePage.type, searchDocuments);
  yield takeLatest(actions.searchChangeDateSelector.type, searchDocuments);
  yield takeLatest(actions.associatePlaceholderWithDocument.type, associate);
  yield takeLatest(actions.removePlaceholder.type, deletePlaceholder);
  yield takeLatest(actions.updateData.type, updateProcedure);
}
