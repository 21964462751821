import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Docs from './containers';
import { actions } from './slice';

export default function Documents() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.startLoading(1));
  }, []);

  return <Docs />;
}
