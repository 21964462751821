import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple, faGooglePlay } from '@fortawesome/free-brands-svg-icons';
import { faLaptop } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

export default function DownloadButtons() {
  return (
    <div className="mb-5">
      <p className="mb-1 text-white h5 text-center font-weight-bold">Disponibile su</p>
      <div className="d-flex justify-content-center flex-wrap">
        <a
          className="button-wrapper"
          href="https://play.google.com/store/apps/details?id=it.ibc.easytaxassistant&amp;hl=it"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="button-webapp">
            <FontAwesomeIcon icon={faGooglePlay} size="2x" />
            <div>
              <h6>Play Store</h6>
            </div>
          </div>
        </a>
        <a
          className="button-wrapper"
          href="https://itunes.apple.com/it/app/easytax-assistant/id1227676752?mt=8"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="button-webapp">
            <FontAwesomeIcon icon={faApple} size="2x" />
            <div>
              <h6>Apple Store</h6>
            </div>
          </div>
        </a>
        <a
          className="button-wrapper"
          href="https://web.easytaxassistant.it"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="button-webapp">
            <FontAwesomeIcon icon={faLaptop} size="2x" />
            <div>
              <h6>WebApp</h6>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}
