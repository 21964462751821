/* eslint-disable react/prop-types */
import React from 'react';
import { SimpleTable } from '@whitelabel/core';
import { useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
// import AsyncSelect from 'react-select/async';
import { selectors } from '../slice';
// import * as api from '../api';

const COLUMNS = [
  {
    Header: 'Nome',
    accessor: 'CategoryName',
  },
  {
    Header: 'Documenti',
    accessor: 'DocCount',
  },
  {
    Header: 'Azioni',
    accessor: 'CategoryId',
    Cell: ({ value }) => (
      <Link to={`documenti/${value}/`} className="btn btn-primary text-white">
        <i className="fa fa-eye" />
      </Link>
    ),
  },
];

export default function Categories() {
  // const [state, setState] = useState(null);
  const categories = useSelector(selectors.selectCategories);
  // const history = useHistory();

  // const loadOptions = async text => {
  //   return api.getAllCategories({ query: text });
  // };

  // const submit = e => {
  //   e.preventDefault();
  //   history.push(`documenti/${state.Id}/`);
  // };

  console.log(categories);

  return (
    <>
      <h5>Documenti</h5>
      {/* <Form onSubmit={submit} className="d-flex align-items-center mb-2"> */}
      {/*  <div className="w-100 mr-1"> */}
      {/*    <AsyncSelect */}
      {/*      value={state} */}
      {/*      onChange={setState} */}
      {/*      loadOptions={loadOptions} */}
      {/*      defaultOptions */}
      {/*      getOptionLabel={opt => opt.Name} */}
      {/*      getOptionValue={opt => opt.Id} */}
      {/*      placeholder="Seleziona categoria" */}
      {/*    /> */}
      {/*  </div> */}
      {/*  <Button type="submit" className="" disabled={!state}> */}
      {/*    Vai */}
      {/*  </Button> */}
      {/* </Form> */}
      <Card>
        <CardBody className="p-0">
          <SimpleTable
            columns={COLUMNS}
            data={categories}
            emptyText="Nessuna Categoria con documenti associati"
          />
        </CardBody>
      </Card>
    </>
  );
}
