/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

/**
 * reducer to manage user data
 * @param state
 * @param action
 * @return {*}
 */
const userSlice = createSlice({
  name: 'user',
  initialState: null,
  reducers: {
    // eslint-disable-next-line no-unused-vars
    loadUser(_, action) {
      return action.payload;
    },
    updateUser(state) {
      state.loading = true;
    },
    errorUpdate(state) {
      delete state.loading;
    },
  },
});

export const { name, actions } = userSlice;
export default userSlice.reducer;
