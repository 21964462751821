// eslint-disable-next-line import/no-extraneous-dependencies
import { all } from '@redux-saga/core/effects';
import macroSaga from './macrocategories/sagas';
import documentsSaga from '../modules/NewDocuments/sagas';
import proceduresSaga from '../modules/Procedures/sagas';
import authSagas from './auth/sagas';

export default function* sagas() {
  yield all([authSagas(), macroSaga(), documentsSaga(), proceduresSaga()]);
}
