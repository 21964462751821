import React from 'react';
import { Button, Card, CardHeader, ListGroup, ListGroupItem } from 'reactstrap';
import CardBody from 'reactstrap/es/CardBody';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

export default function UploadDocument({
  handleDrop,
  files,
  removeFile,
  loading,
  upload,
  key,
  reset,
  uploaded,
  filesStatus,
}) {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: '.pdf,.jpg',
  });

  console.log(files);

  return (
    <Card className="shadow-lg border-0 mt-5">
      <CardHeader className="shadow h3 bg-primary rounded-bottom">Carica documenti</CardHeader>
      <CardBody className="pt-0 mt-3">
        <>
          {(loading ? false : !uploaded) && (
            <>
              <div {...getRootProps({ className: 'dropzone' })} style={{ height: '10rem' }}>
                <input {...getInputProps()} />
                <p>Trascina e rilascia i files, o clicca per selezionare i files</p>
              </div>
            </>
          )}
          {files.length > 0 && (
            <>
              <h4 className="mt-4">Riepilogo</h4>
              <ListGroup>
                {files.map((f, i) => (
                  <ListGroupItem className="d-flex justify-content-between" key={f.path}>
                    <strong>{f.path}</strong>
                    {(loading ? false : !uploaded) && (
                      <Button color="danger" onClick={() => removeFile(i)} className="text-white">
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    )}
                    {!loading && uploaded && filesStatus[i]}
                  </ListGroupItem>
                ))}
              </ListGroup>
              <div className="d-flex justify-content-center mt-3">
                {!loading && !uploaded && (
                  <Button size="lg" onClick={upload}>
                    Carica
                  </Button>
                )}
                {!loading && uploaded && (
                  <Button size="lg" onClick={reset}>
                    Reset
                  </Button>
                )}
                {loading && <span>Upload in corso...</span>}
              </div>
            </>
          )}
        </>
      </CardBody>
    </Card>
  );
}
