/* eslint-disable no-param-reassign */
import indexOf from 'lodash/indexOf';
import { createSlice, createSelector } from '@reduxjs/toolkit';

export const proceduresInitialState = {
  list: null,
  loading: false,
  error: false,
  user: null,
  page: 1,
  totPages: 1,
  descending: true,
  key: 1,
  orderBy: null,
  status: null,
  dateSelector: {
    startDate: null,
    endDate: null,
  },
};

const proceduresSlice = createSlice({
  name: 'procedures',
  initialState: proceduresInitialState,
  reducers: {
    load(state, action) {
      state.loading = true;
      state.page = action.payload;
    },
    loadSuccess(state, action) {
      state.loading = false;
      state.list = action.payload.Practices;
      state.page = action.payload.Page;
      state.totPages = action.payload.TotPages;
    },
    loadFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setDateSelector(state, action) {
      state.loading = true;
      const { dateStart, dateEnd } = action.payload;
      state.dateSelector = { dateStart, dateEnd };
      state.page = 1;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    create(state) {
      state.loading = true;
    },
    reset(state) {
      state.user = null;
      state.page = 1;
      state.totPages = 1;
      state.showAll = false;
      state.descending = true;
      state.key += 1;
    },
    setDescending(state, action) {
      state.descending = action.payload;
    },
    setOrderBy(state, action) {
      state.orderBy = action.payload;
    },
    setShowAll(state, action) {
      state.showAll = action.payload;
    },
    update(state, action) {
      const docIndex = indexOf(state.list, document => document.Id === action.payload.Id);
      state.list[docIndex] = action.payload;
    },
    exportAll() {},
  },
});

export const { reducer, actions, name } = proceduresSlice;

export const selectors = {
  getState: createSelector(
    state => state[name],
    _ => _,
  ),
  getFilters: createSelector(
    state => state[name],
    ({ user, showAll, dateSelector, status }) => ({
      userId: user && user.Id,
      showAll,
      startDate: dateSelector && dateSelector.dateStart,
      endDate: dateSelector && dateSelector.dateEnd,
      status: status ? status.value : '',
    }),
  ),
  getFiltersWithPage: createSelector(
    state => state && state[name],
    ({ page, user, descending, orderBy, showAll, dateSelector, status }) => ({
      userId: user && user.Id,
      descending,
      orderBy,
      showAll,
      pageStart: page,
      startDate: dateSelector && dateSelector.dateStart,
      endDate: dateSelector && dateSelector.dateEnd,
      status: status ? status.value : '',
    }),
  ),
  canCreate: createSelector(
    state => state && state[name],
    ({ loading, list }) =>
      process.env.NODE_ENV !== 'production' ||
      (!loading && list && list.filter(p => p.Status === 'Aperta').length === 0),
  ),
};
