import * as Sentry from '@sentry/browser';

/**
 * configure sentry using current version number
 */
function configSentry() {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      release: `assocaaf@${process.env.REACT_APP_VERSION}`,
    });
  }
}
export default configSentry();
