// eslint-disable-next-line import/no-extraneous-dependencies
import { takeLatest, call, put } from '@redux-saga/core/effects';
import { NotAuthenticatedError } from '@whitelabel/core';
import { toast } from 'react-toastify';
import { actions } from './reducer';
import * as apis from './apis';
import { APP_LOGOUT } from '../constants';

function* loadMacroCategories() {
  try {
    const data = yield call(apis.getMacroCategories);
    yield put(actions.loadSuccess(data));
  } catch (e) {
    if (e instanceof NotAuthenticatedError) {
      toast.error('La sessione è scaduta');
      yield put({ type: APP_LOGOUT });
    } else {
      toast.error("Si è verificato un'errore");
    }
  }
}

export default function* macroSaga() {
  yield takeLatest(actions.loadStart, loadMacroCategories);
}
