import { RSAA } from 'redux-api-middleware';

export default function injectTokenMiddleware(store) {
  return next => action => {
    const apiCall = action[RSAA];

    if (!apiCall) {
      return next(action);
    }

    const { token } = store.getState();
    const headers = apiCall.headers || {};

    const nextAction = {
      ...apiCall,
      params: {
        ...apiCall.params,
        accessToken: token,
      },
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
    };
    return next({ [RSAA]: nextAction });
  };
}
