// eslint-disable-next-line import/no-extraneous-dependencies
import { takeLatest, call, put, select } from '@redux-saga/core/effects';
import find from 'lodash/find';
import { configs } from '@whitelabel/core';
import { actions, selectors } from './slice';
import * as apis from './apis';

const { DOCUMENTS_UPDATE } = configs;

function* loadDocuments() {
  const filters = yield select(selectors.getFiltersWithPage);
  try {
    const data = yield call(apis.fetchDocuments, filters);
    yield put(actions.successLoading(data));
  } catch (e) {
    console.log(e);
  }
}

function* changeFilter(action) {
  if (action.type === DOCUMENTS_UPDATE) {
    const { pageStart } = yield select(selectors.getFiltersWithPage);
    yield put(actions.startLoading(pageStart));
  } else if (
    action.type !== actions.setDate ||
    (action.payload.startDate && action.payload.endDate)
  ) {
    yield put(actions.startLoading(1));
  }
}

function* forceMainCategory(action) {
  const state = yield select(st => st);
  if (action.payload) {
    if (!state.documents.macro) {
      const macro = find(state.macrocategories.list, mc => mc.Id === action.payload.MainCategoryId);
      yield put(actions.setMainCategoryWithoutReset(macro));
    }
  } else {
    yield put(actions.setMainCategory(null));
  }
}

export default function* documentsSaga() {
  yield takeLatest(actions.startLoading.type, loadDocuments);
  yield takeLatest(actions.setDescending.type, changeFilter);
  yield takeLatest(actions.setShowAll.type, changeFilter);
  yield takeLatest(actions.setOrderBy.type, changeFilter);
  yield takeLatest(actions.setCategory.type, changeFilter);
  yield takeLatest(actions.setCategory.type, forceMainCategory);
  yield takeLatest(actions.setMainCategory.type, changeFilter);
  yield takeLatest(actions.setDate.type, changeFilter);
  yield takeLatest(DOCUMENTS_UPDATE, changeFilter);
}
