/* eslint-disable react/prop-types */
import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';
import { Field } from 'redux-form';
import { Asterisk, RFSelectAsync, validation, enumApi } from '@whitelabel/core';

export default function Profile({ user, handleSubmit }) {
  console.log(user);
  return (
    <Card className="shadow-lg border-0 mt-5">
      <CardHeader className="shadow h3 bg-primary rounded-bottom">Il mio profilo</CardHeader>
      <CardBody className="pt-0 mt-3">
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>Nome</Label>
            <Input tag={Field} name="Name" component="input" />
          </FormGroup>
          <FormGroup>
            <Label>Cognome</Label>
            <Input tag={Field} name="Surname" component="input" />
          </FormGroup>
          <FormGroup>
            <Label>Indirizzo</Label>
            <Input tag={Field} name="Address" component="input" />
          </FormGroup>
          <FormGroup>
            <Label>
              Comune <Asterisk />
            </Label>
            <Field
              component={RFSelectAsync}
              defaultOptions
              loadOptions={text => {
                if (text.length < 4) {
                  return [];
                }
                return enumApi.getCity({ query: text });
              }}
              name="City"
              validate={validation.isRequired}
              placeholder="Cerca Comune"
              getOptionLabel={opt => `${opt.Name} - ${opt.CadastralCode}`}
              getOptionValue={opt => opt.CadastralCode}
              isClearable
            />
          </FormGroup>
          <FormGroup>
            <Label>Codice Fiscale</Label>
            <Input tag={Field} name="FiscalCode" component="input" />
          </FormGroup>
          <FormGroup>
            <Label>Cod. Fiscale Parente</Label>
            <Input tag={Field} name="FiscalCodeRelative" component="input" />
          </FormGroup>
          <FormGroup>
            <Label>Telefono</Label>
            <Input tag={Field} name="PhoneNumber" component="input" />
          </FormGroup>
          {/* eslint-disable-next-line react/prop-types */}
          {user && user.CommercialistaDisplayName && (
            <FormGroup>
              <Label>Commercialista</Label>
              <Input disabled tag={Field} name="CommercialistaDisplayName" component="input" />
            </FormGroup>
          )}
          <Button type="submit" disabled={user.loading}>
            Salva {user.loading && <Spinner className="ml-2" size="sm" />}
          </Button>
        </Form>
      </CardBody>
    </Card>
  );
}
