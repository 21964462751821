import { compose } from 'recompose';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import Profile from '../components/Profile';
import { actions as userActions } from '../../../store/auth/user';

const enhance = compose(
  connect(
    ({ user }) => ({
      user,
      initialValues: {
        ...user,
        City: user.Common
          ? {
              Name: user.Common.Name,
              CadastralCode: user.Common.CadastralCode,
              Province: user.Common.Province,
            }
          : null,
      },
    }),
    dispatch => ({
      onSubmit: data => dispatch(userActions.updateUser(data)),
    }),
  ),
  reduxForm({ form: 'userprofile' }),
);

export default enhance(Profile);
