/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export const initialState = {
  data: null,
  loading: true,
  current: null,
  deleted: false,
  saving: false,
};

const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    startLoading(state, action) {
      state.loading = true;
      state.deleted = false;
      state.saving = false;
      state.current = action.payload;
    },
    successLoading(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
    deleteDocument(state) {
      state.loading = true;
    },
    successDelete(state) {
      state.loading = false;
      state.deleted = true;
    },
    updateDocument(state) {
      state.saving = true;
    },
    updateEnd(state) {
      state.saving = false;
    },
  },
});

export const { reducer, actions, name } = documentSlice;

export const selectors = {
  selectState: createSelector(
    state => state && state[name],
    state => state,
  ),
};
