import { Modal, ModalBody, ModalHeader, Button, Spinner } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { SimpleTable, usePagination, formatMoney } from '@whitelabel/core';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import { actions, selectors, selectors as selector } from '../slice';

const columns = associate => [
  {
    Header: 'Nome documento',
    accessor: 'Name',
    Cell: ({ value, row: { original } }) => (
      <>
        <b>{original.UserName}</b>
        <br />
        {value}
      </>
    ),
  },
  {
    Header: 'Data',
    accessor: 'Date',
    Cell: ({ value }) => format(new Date(value), 'dd/MM/yyyy'),
  },
  {
    Header: 'Data Caricamento',
    accessor: 'CreatedAt',
    Cell: ({ value }) => format(new Date(value), 'dd/MM/yyyy HH:mm'),
  },
  {
    Header: 'Importo',
    accessor: 'Amount',
    Cell: ({ value }) => formatMoney(value),
  },
  {
    Header: '',
    accessor: 'Id',
    Cell: ({ value }) => (
      <>
        <Button tag={Link} className="m-1" color="primary" to={`../preview/${value}`}>
          Visualizza
        </Button>
        <Button className="m-1" onClick={() => associate(value)}>
          Seleziona
        </Button>
      </>
    ),
  },
];

export default function SearchDocuments() {
  const { categoryId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [focusedInput, setFocusedInput] = useState();
  const activeCategory = useSelector(selectors.selectActiveCategory);
  const { loading, list, page, tot: totPages } = useSelector(selector.selectSearchDocs);
  const { dateSelector } = useSelector(selector.selectSearchFilters);

  useEffect(() => {
    dispatch(actions.searchDocs(categoryId));
  }, [categoryId]);

  const associate = documentId => {
    dispatch(actions.associatePlaceholderWithDocument({ documentId }));
  };

  const pagination = usePagination({
    totPages,
    loadPage: pageNum => dispatch(actions.searchChangePage(pageNum)),
    page,
  });

  return (
    <Modal isOpen={categoryId} id="document-modal-preview" toggle={history.goBack}>
      <ModalHeader toggle={history.goBack}>
        Associa Documenti - {activeCategory.loading ? <Spinner /> : activeCategory.CategoryName}
      </ModalHeader>
      <ModalBody className="p-0">
        <DateRangePicker
          startDate={dateSelector.dateStart && moment(dateSelector.dateStart, 'YYYY-MM-DD')}
          endDate={dateSelector.dateEnd && moment(dateSelector.dateEnd, 'YYYY-MM-DD')}
          focusedInput={focusedInput}
          onDatesChange={({ endDate, startDate }) =>
            dispatch(
              actions.searchChangeDateSelector({
                dateStart: startDate && moment(startDate).format('YYYY-MM-DD'),
                dateEnd: endDate && moment(endDate).format('YYYY-MM-DD'),
              }),
            )
          }
          onFocusChange={setFocusedInput}
          isOutsideRange={() => false}
          startDatePlaceholderText="Inizio"
          endDatePlaceholderText="Fine"
          noBorder
          showClearDates
        />
        <SimpleTable
          data={list}
          loading={loading}
          columns={columns(associate)}
          emptyText="Nessun documento trovato"
        />
        {!loading && pagination}
      </ModalBody>
    </Modal>
  );
}
