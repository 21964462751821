/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import find from 'lodash/find';
import remove from 'lodash/remove';
import pick from 'lodash/pick';
import clone from 'lodash/cloneDeep';

export const initialState = {
  data: null,
  loading: true,
  error: false,
  id: null,
  categoryId: null,
  categoryName: null,
  categoryLoading: false,
  updating: false,
  updateKey: 0,
  editable: false,
  documents: {
    list: [],
    loading: false,
    error: false,
    page: 1,
    tot: 1,
  },
  messages: {
    list: [],
    error: false,
    loading: false,
  },
  searchDocuments: {
    filters: {
      dateSelector: {
        dateStart: null,
        dateEnd: null,
      },
    },
    docs: 0,
    amount: 0,
    page: 1,
    tot: 1,
    list: [],
    loading: false,
  },
};

const procedureSlice = createSlice({
  name: 'procedure',
  initialState,
  reducers: {
    addCategory() {},
    removeProcedure() {},
    loadProcedure(state, action) {
      state.loading = true;
      state.editable = false;
      state.id = action.payload;
    },
    setProcedure(state, action) {
      state.loading = false;
      state.updating = false;
      state.data = action.payload;
      state.updateKey += 1;
    },
    errorLoadingProcedure(state) {
      state.loading = false;
      state.error = true;
    },
    loadDocuments(state, action) {
      state.documents.loading = true;
      state.categoryId = action.payload.categoryId;
      state.categoryLoading = true;
    },
    loadMessages(state) {
      state.messages.loading = true;
    },
    setMessages(state, action) {
      state.messages.loading = false;
      state.messages.list = action.payload;
    },
    setDocuments(state, action) {
      state.documents.loading = false;
      state.documents.list = action.payload.Documents;
      state.documents.page = action.payload.Page;
      state.documents.tot = action.payload.TotPages;
    },
    documentsChangePage(state) {
      state.documents.loading = true;
    },
    errorLoadingMessages(state) {
      state.messages.loading = false;
      state.messages.error = true;
    },
    errorLoadingDocuments(state) {
      state.documents.loading = false;
      state.documents.error = true;
    },
    sendMessage(state, action) {
      state.messages.list.unshift({
        Id: new Date().getTime(),
        Message: action.payload,
        AdminCreatorId: true,
      });
    },
    searchDocs(state, action) {
      state.searchDocuments.loading = true;
      if (action.payload) {
        state.categoryId = action.payload;
        state.categoryLoading = true;
      }
    },
    searchChangePage(state) {
      state.searchDocuments.loading = true;
    },
    searchChangeDateSelector(state, action) {
      state.searchDocuments.loading = true;
      const { dateStart, dateEnd } = action.payload;
      state.searchDocuments.filters.dateSelector = { dateStart, dateEnd };
      state.searchDocuments.page = 1;
    },
    setSearchDocs(state, action) {
      state.searchDocuments.loading = false;
      state.searchDocuments.page = action.payload.Page;
      state.searchDocuments.tot = action.payload.TotPages;
      state.searchDocuments.list = action.payload.Documents;
      state.searchDocuments.docs = action.payload.TotDocuments;
      state.searchDocuments.amount = action.payload.AmountSum;
    },
    searchRemoveDoc(state, action) {
      remove(state.searchDocuments.list, d => d.Id === action.payload);
    },
    associatePlaceholderWithDocument(state, action) {
      state.searchDocuments.loading = true;
      const { placeholderId, documentId, documentName } = action.payload;
      state.documents.list.forEach(d => {
        if (d.Id === placeholderId) {
          d.DocumentId = documentId;
          d.DocumentName = documentName;
        }
      });
    },
    removeDocument(state, action) {
      state.documents.list.forEach(d => {
        if (d.Id === action.payload) {
          d.DocumentId = null;
          d.DocumentName = null;
        }
      });
    },
    removePlaceholder(state, action) {
      remove(state.documents.list, d => d.Id === action.payload);
    },
    createPlaceholder() {},
    setStatus(state, action) {
      state.data.Status = action.payload;
    },
    updateData(state) {
      state.updating = true;
    },
    toggleEdit(state) {
      state.editable = !state.editable;
    },
    setCategoryName(state, action) {
      state.categoryName = action.payload;
      state.categoryLoading = false;
    },
  },
});

export const { name, reducer, actions } = procedureSlice;

const getDomain = state => state.procedure || initialState;
const getData = proc => proc.data || { Documents: [], Messages: [] };

const selectData = createSelector(getDomain, getData);

const selectDocuments = createSelector(getDomain, data => data.documents);
const selectSearchDocs = createSelector(getDomain, data => data.searchDocuments);
const selectCategories = createSelector(selectData, data => data.DocCategories);
// const selectActiveCategoryId = createSelector(getDomain, state => state.categoryId);
const selectActiveCategory = createSelector(getDomain, state => ({
  CategoryId: state.categoryId,
  CategoryName: state.categoryName,
  loading: state.categoryLoading,
}));

export const selectors = {
  selectId: createSelector(getDomain, state => state.id),
  selectState: createSelector(getDomain, a => a),
  selectInitialData: createSelector(getDomain, ({ data }) => {
    const newData = clone(data);

    newData.UserDetails = newData.UserDetails || {};

    newData.Irpef8mille = newData.Irpef8mille
      ? {
          Id: newData.Irpef8mille,
          Name: newData.Irpef8milleName,
        }
      : null;
    newData.Irpef5mille = newData.Irpef5mille
      ? {
          Id: newData.Irpef5mille,
          Name: newData.Irpef5milleName,
        }
      : null;
    newData.Irpef2mille = newData.Irpef2mille
      ? {
          Id: newData.Irpef2mille,
          Name: newData.Irpef2milleName,
        }
      : null;

    newData.UserCity =
      newData.UserCityId && newData.UserDetails.CadastralCode
        ? {
            Id: newData.UserCityId,
            Name: newData.UserCityName,
            CadastralCode: newData.UserDetails.CadastralCode,
          }
        : null;
    newData.UserRegion =
      newData.UserRegionId && newData.UserRegionName
        ? {
            Id: newData.UserRegionId,
            Name: newData.UserRegionName,
          }
        : null;
    newData.UserProvince = newData.UserProvinceId
      ? {
          Id: newData.UserProvinceId,
          Name: newData.UserProvinceName,
        }
      : null;

    if (newData.UserDetails.AmmSocialiTypeId) {
      newData.UserDetails.AmmSocialiType = {
        Id: newData.UserDetails.AmmSocialiTypeId,
        Name: newData.UserDetails.AmmSocialiTypeName,
      };
    } else {
      newData.UserDetails.AmmSocialiType = null;
    }

    return newData;
  }),
  selectCategories,
  selectActiveCategory,
  selectDocuments,
  selectSearchFilters: createSelector(selectSearchDocs, state => state.filters),
  selectAssociatedDocs: createSelector(selectDocuments, docs =>
    docs.list.filter(d => d.DocumentId),
  ),
  selectPlaceholderById: id =>
    createSelector(selectDocuments, docs => find(docs.list, d => d.Id === id)),
  selectMessages: createSelector(getDomain, data => data.messages),
  selectUserDetails: createSelector(selectData, data => ({
    ...pick(data, [
      'UserName',
      'FiscalCodeUser',
      'Email',
      'PhoneNumber',
      'DichAnnoScorso',
      'DichAnniPrecedenti',
      'DichCongiunta',
      'DelegaCUInps',
    ]),
    ...data.UserDetails,
  })),
  selectSearchDocs,
  selectCurrentPlaceholder: createSelector(getDomain, data => data.searchDocuments),
};
