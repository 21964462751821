import { LOAD_TOKEN } from '../constants';

const initialState = null;

/**
 * reducer that handles TOKEN
 * @param state
 * @param action
 * @return {*}
 */
function tokenReducer(state = initialState, action) {
  if (action.type === LOAD_TOKEN) {
    return action.token;
  }

  return state;
}

export default tokenReducer;
