import PropTypes from 'prop-types';
import React from 'react';
import AppNavbarBrand from '@coreui/react/lib/NavbarBrand';
import { AppSidebarMinimizer } from '@coreui/react';
import { Nav, NavItem, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import logo from '../../../assets/logo-full.png';
import logoSmall from '../../../assets/logo.png';

export default function DefaultHeader({ logout }) {
  return (
    <>
      <AppSidebarMinimizer className="btn bg-white text-primary my-auto mx-3 ">
        <FontAwesomeIcon icon={faBars} size="lg" />
      </AppSidebarMinimizer>
      <AppNavbarBrand
        href="/"
        full={{
          src: logo,
        }}
        minimized={{
          src: logoSmall,
        }}
      />
      <Nav className="ml-auto" navbar>
        {/* <NavItem className="px-3 text-muted">Documenti Caricati: {documents}</NavItem> */}
        <NavItem className="px-3">
          <Button
            onClick={logout}
            color="primary"
            className="btn-round text-uppercase font-weight-bold"
          >
            Logout
          </Button>
        </NavItem>
      </Nav>
    </>
  );
}

DefaultHeader.propTypes = {
  logout: PropTypes.func.isRequired,
};
