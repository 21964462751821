export const ADMIN_ROLE = 'Admin';
export const DAY_SIZE = 33;

export const TYPE_OPTIONS = [
  { value: 'Single', label: 'Singolo' },
  { value: 'Double', label: 'Congiunto' },
];

export const NOTES_TYPES = {
  NON_LEGGIBILE: 'Non leggibile',
  DUPLICATO: 'Duplicato',
  ALTRO: 'Altro',
};

export const NOTES_OPTIONS = [NOTES_TYPES.NON_LEGGIBILE, NOTES_TYPES.DUPLICATO, NOTES_TYPES.ALTRO];

export const IDENTDOCUMENTS_NOTES = [
  'Documento non valido',
  'Documento di riconoscimento scaduto',
  'Immagine non leggibile',
  'Formato file non supportato',
  'Altro',
];

export const CU_CATEGORIES = [
  '2b8771e1-d19f-11ea-b593-0242ab23801e',
  '4af58208-d19f-11ea-b593-0242ab23801e',
];

export const CU_ROW_TYPES = {
  IMPORTO: 'Importo',
  INTERO: 'Intero',
  DATA: 'Data',
  TEXT: 'Testo',
  ANNO: 'Anno',
};

export const CU_GROUPS = {
  DICHIARAZIONE: 'Dichiarazione',
  ANAGRAFICA: 'Anagrafica',
  REDDITI: 'Redditi',
  RITENUTE: 'Ritenute',
  CREDITI_NON_RIMBORSATI: 'Crediti non rimborsati',
  ACCONTI_DICH: 'Acconti {YEAR} Dichiarante',
  CREDITI_NON_RIMBORSATI_2: 'Crediti non rimborsati 2',
  BONUS: 'Codice Bonus',
  ACCONTI_CON: 'Acconti {YEAR} coniuge',
  CONTRIBUTI_PRIMA_OCC: 'Contributi prev. prima occupazione',
  CONTRIBUTI_FAM: 'Contributi prev. familiari a carico',
  SOMME_EROG: 'Somme erogate per premi',
};

export const CU_ROWS = [
  {
    key: 'AnnoRiferimento',
    label: 'Anno Fiscale',
    type: CU_ROW_TYPES.INTERO,
    group: CU_GROUPS.DICHIARAZIONE,
  },
  {
    key: 'Nome',
    label: 'Nome',
    type: CU_ROW_TYPES.TEXT,
    group: CU_GROUPS.ANAGRAFICA,
    full: true,
  },
  {
    key: 'Cognome',
    label: 'Cognome',
    type: CU_ROW_TYPES.TEXT,
    group: CU_GROUPS.ANAGRAFICA,
    full: true,
  },
  {
    key: 'CodiceFiscale',
    label: 'Codice Fiscale',
    type: CU_ROW_TYPES.TEXT,
    group: CU_GROUPS.ANAGRAFICA,
    full: true,
  },
  {
    key: 'Field1',
    label: 'Punto 1',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.REDDITI,
    desc: 'Tempo indeterminato',
  },
  {
    key: 'Field2',
    label: 'Punto 2',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.REDDITI,
    desc: 'Tempo determinato',
  },
  {
    key: 'Field3',
    label: 'Punto 3',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.REDDITI,
    desc: 'Redditi Pensione',
  },
  {
    key: 'Field4',
    label: 'Punto 4',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.REDDITI,
    desc: 'Altri redditi assimilati',
  },
  {
    key: 'Field5',
    label: 'Punto 5',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.REDDITI,
    desc: 'Assegni Coniuge',
  },
  {
    key: 'Field6',
    label: 'Punto 6',
    type: CU_ROW_TYPES.INTERO,
    group: CU_GROUPS.REDDITI,
    desc: 'Numero di giorni per i quali spettano le detrazioni Lavoro Dipendente',
  },
  {
    key: 'Field7',
    label: 'Punto 7',
    type: CU_ROW_TYPES.INTERO,
    group: CU_GROUPS.REDDITI,
    desc: 'Numero di giorni per i quali spettano le detrazioni Pensione',
  },
  {
    key: 'Field21',
    label: 'Punto 21',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.RITENUTE,
    desc: 'Ritenute IRPEF',
  },
  {
    key: 'Field22',
    label: 'Punto 22',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.RITENUTE,
    desc: 'Add. Regionale',
  },
  {
    key: 'Field26',
    label: 'Punto 26',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.RITENUTE,
    desc: 'ADDIZIONALE COMUNALE ALL’IRPEF Acconto {YEAR}',
  },
  {
    key: 'Field27',
    label: 'Punto 27',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.RITENUTE,
    desc: 'ADDIZIONALE COMUNALE ALL’IRPEF Saldo {YEAR}',
  },
  {
    key: 'Field29',
    label: 'Punto 29',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.RITENUTE,
    desc: 'ADDIZIONALE COMUNALE ALL’IRPEF Acconto {YEAR_NEXT}',
  },
  {
    key: 'Field64',
    label: 'Punto 64',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.CREDITI_NON_RIMBORSATI,
    desc: 'Credito Irpef non rimborsato',
  },
  {
    key: 'Field74',
    label: 'Punto 74',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.CREDITI_NON_RIMBORSATI,
    desc: 'Credito Add. Regionale Irpef non rimborsato',
  },
  {
    key: 'Field84',
    label: 'Punto 84',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.CREDITI_NON_RIMBORSATI,
    desc: 'Credito Add. Comunale Irpef non rimborsato',
  },
  {
    key: 'Field94',
    label: 'Punto 94',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.CREDITI_NON_RIMBORSATI,
    desc: 'Credito cedolare secca non rimborsato',
  },
  {
    key: 'Field121',
    label: 'Punto 121',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.ACCONTI_DICH,
    desc: 'Primo acconto Irpef trattenuto nell’anno',
  },
  {
    key: 'Field122',
    label: 'Punto 122',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.ACCONTI_DICH,
    desc: 'Secondo/unico acc. Irpef tratt. nell’anno',
  },
  {
    key: 'Field124',
    label: 'Punto 124',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.ACCONTI_DICH,
    desc: 'Acconto all’addizionale comunale all’Irpef',
  },
  {
    key: 'Field126',
    label: 'Punto 126',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.ACCONTI_DICH,
    desc: 'Prima rata di acconto cedolare secca',
  },
  {
    key: 'Field127',
    label: 'Punto 127',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.ACCONTI_DICH,
    desc: 'Seconda/unica rata di acconto cedol. secca',
  },
  {
    key: 'Field264',
    label: 'Punto 264',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.CREDITI_NON_RIMBORSATI_2,
    desc: 'Credito Irpef non rimborsato',
  },
  {
    key: 'Field274',
    label: 'Punto 274',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.CREDITI_NON_RIMBORSATI_2,
    desc: 'Credito Add. Regionale Irpef non rimborsato',
  },
  {
    key: 'Field284',
    label: 'Punto 284',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.CREDITI_NON_RIMBORSATI_2,
    desc: 'Credito Add. Comunale Irpef non rimborsato',
  },
  {
    key: 'Field294',
    label: 'Punto 294',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.CREDITI_NON_RIMBORSATI_2,
    desc: 'Credito cedolare secca non rimborsato',
  },
  {
    key: 'Field391',
    label: 'Punto 391',
    type: CU_ROW_TYPES.INTERO,
    group: CU_GROUPS.BONUS,
    desc: 'Codice bonus',
  },
  {
    key: 'Field392',
    label: 'Punto 392',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.BONUS,
    desc: 'Bonus erogato',
  },
  {
    key: 'Field321',
    label: 'Punto 321',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.ACCONTI_CON,
    desc: 'Primo acconto Irpef trattenuto nell’anno',
  },
  {
    key: 'Field322',
    label: 'Punto 322',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.ACCONTI_CON,
    desc: 'Secondo/unico acc. Irpef tratt. nell’anno',
  },
  {
    key: 'Field324',
    label: 'Punto 324',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.ACCONTI_CON,
    desc: 'Acconto all’addizionale comunale all’Irpef',
  },
  {
    key: 'Field326',
    label: 'Punto 326',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.ACCONTI_CON,
    desc: 'Prima rata di acconto cedolare secca',
  },
  {
    key: 'Field327',
    label: 'Punto 327',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.ACCONTI_CON,
    desc: 'Seconda/unica rata di acconto cedol. secca',
  },
  {
    key: 'Field411',
    label: 'Punto 411',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.CONTRIBUTI_PRIMA_OCC,
    desc: 'Previdenza complementare',
  },
  {
    key: 'Field412',
    label: 'Punto 412',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.CONTRIBUTI_PRIMA_OCC,
    desc: 'Contrib. dedotti redditi pt. 1,2,3,4,5',
  },
  {
    key: 'Field413',
    label: 'Punto 413',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.CONTRIBUTI_PRIMA_OCC,
    desc: 'Contrib. non dedotti redditi pt. 1,2,3,4,5',
  },
  {
    key: 'Field415',
    label: 'Punto 415',
    type: CU_ROW_TYPES.DATA,
    group: CU_GROUPS.CONTRIBUTI_PRIMA_OCC,
    desc: 'CONTRIBUTI LAV. PRIMA OCCUPAZIONE Iscrizione al fondo',
  },
  {
    key: 'Field417',
    label: 'Punto 417',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.CONTRIBUTI_PRIMA_OCC,
    desc: 'CONTRIBUTI LAV. PRIMA OCCUPAZIONE Importi ecc.esclusi dai redditi pt. 1,2,3,4,5',
  },
  {
    key: 'Field421',
    label: 'Punto 421',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.CONTRIBUTI_FAM,
    desc: 'CONTRIBUTI PER FAMILIARI A CARICO Versati',
  },
  {
    key: 'Field422',
    label: 'Punto 422',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.CONTRIBUTI_FAM,
    desc: 'CONTRIBUTI PER FAMILIARI A CARICO Dedotti',
  },
  {
    key: 'Field423',
    label: 'Punto 423',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.CONTRIBUTI_FAM,
    desc: 'CONTRIBUTI PER FAMILIARI A CARICO Non dedotti',
  },
  {
    key: 'Field571',
    label: 'Punto 571',
    type: CU_ROW_TYPES.INTERO,
    group: CU_GROUPS.SOMME_EROG,
    desc: 'Codice',
  },
  {
    key: 'Field572',
    label: 'Punto 572',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.SOMME_EROG,
    desc: 'Premi risultato imposta sostitutiva',
  },
  {
    key: 'Field573',
    label: 'Punto 573',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.SOMME_EROG,
    desc: 'Benefit',
  },
  {
    key: 'Field576',
    label: 'Punto 576',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.SOMME_EROG,
    desc: 'Imposta sostitutiva',
  },
  {
    key: 'Field578',
    label: 'Punto 578',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.SOMME_EROG,
    desc: 'Premi risultato tassazione ordinaria',
  },
  {
    key: 'Field579',
    label: 'Punto 579',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.SOMME_EROG,
    desc: 'Benefit tass. ord.',
  },
  {
    key: 'Field591',
    label: 'Punto 591',
    type: CU_ROW_TYPES.INTERO,
    group: CU_GROUPS.SOMME_EROG,
    desc: 'Codice',
  },
  {
    key: 'Field592',
    label: 'Punto 592',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.SOMME_EROG,
    desc: 'Premi risultato imposta sostitutiva',
  },
  {
    key: 'Field593',
    label: 'Punto 593',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.SOMME_EROG,
    desc: 'Benefit',
  },
  {
    key: 'Field596',
    label: 'Punto 596',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.SOMME_EROG,
    desc: 'Imposta sostitutiva',
  },
  {
    key: 'Field598',
    label: 'Punto 598',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.SOMME_EROG,
    desc: 'Premi risultato tassazione ordinaria',
  },
  {
    key: 'Field599',
    label: 'Punto 599',
    type: CU_ROW_TYPES.IMPORTO,
    group: CU_GROUPS.SOMME_EROG,
    desc: 'Benefit tass. ord.',
  },
];

export const CU_GROUPS_ORDER = [
  CU_GROUPS.DICHIARAZIONE,
  CU_GROUPS.ANAGRAFICA,
  CU_GROUPS.REDDITI,
  CU_GROUPS.RITENUTE,
  CU_GROUPS.CREDITI_NON_RIMBORSATI,
  CU_GROUPS.ACCONTI_DICH,
  CU_GROUPS.CREDITI_NON_RIMBORSATI_2,
  CU_GROUPS.ACCONTI_CON,
  CU_GROUPS.BONUS,
  CU_GROUPS.CONTRIBUTI_PRIMA_OCC,
  CU_GROUPS.CONTRIBUTI_FAM,
  CU_GROUPS.SOMME_EROG,
];

export const CU_MASK = CU_GROUPS_ORDER.map(g => ({
  label: g,
  rows: CU_ROWS.filter(r => r.group === g),
}));

export const UTENTE = 'UTENTE';
export const COMMERCIALISTA = 'COMMERCIALISTA';

export const FEATURE_FLAGS = {
  ALLOW_SAVE: true,
  NOTIFICATION: true,
  UPLOAD: true,
};

export const LOGO_UPLOAD_MAX_SIZE = 500 * 1024;
export const CHECK_NOTIFICATIONS_MILLISECONDS = 1000 * 60;
