/* eslint-disable react/prop-types */

import PropTypes from 'prop-types';
import React from 'react';
import CardBody from 'reactstrap/es/CardBody';
import Card from 'reactstrap/es/Card';
import Label from 'reactstrap/es/Label';
import FormGroup from 'reactstrap/es/FormGroup';
import Input from 'reactstrap/es/Input';
import Form from 'reactstrap/es/Form';
import Button from 'reactstrap/es/Button';
import Col from 'reactstrap/es/Col';
import PasswordMask from 'react-password-mask';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactSwitch from 'react-switch';

import { Row } from 'reactstrap';
import { Link } from 'react-router-dom';

import logo from '../../../assets/logo.png';
import DownloadButtons from '../../../components/DownloadButtons';

export default function RegistrationPage({
  email,
  password,
  loading = false,
  setEmail,
  setPassword,
  onSubmit,
  setThirdParty,
  setFirstName,
  setLastName,
  setPasswordConfirm,
  passwordConfirm,
  lastName,
  firstName,
  thirdParty,
  submitted,
}) {
  if (submitted) {
    return (
      <div
        className="d-flex justify-content-center align-items-center bg-login-background"
        style={{ minHeight: '100vh' }}
      >
        <Col md={6}>
          <Card className="border-0">
            <CardBody className="px-5">
              <h1 className="text-center text-newgreen">Benvenuto!</h1>
              <p className="text-center">
                Controlla la tua casella email e conferma il tuo indirizzo email per poter accedere
              </p>
            </CardBody>
          </Card>
          <DownloadButtons />
        </Col>
      </div>
    );
  }

  return (
    <div
      className="d-flex justify-content-center align-items-center bg-login-background"
      style={{ minHeight: '100vh' }}
    >
      <Col md={6}>
        <Card className="border-0 mt-3">
          <CardBody className="px-5">
            <img src={logo} alt="logo easytax assistant" className="d-block mx-auto" />
            <h3 className="text-center text-newgreen">Prova subito EasyTax</h3>
            <Form className="md-form" onSubmit={onSubmit}>
              <Row className="mb-0">
                <Col md={6}>
                  <FormGroup className="mb-0">
                    <Label className="d-none">Nome</Label>
                    <Input placeholder="Nome*" required onChange={setFirstName} value={firstName} />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="mb-0">
                    <Label className="d-none">Cognome</Label>
                    <Input
                      placeholder="Cognome*"
                      required
                      onChange={setLastName}
                      value={lastName}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup className="mb-4">
                <Label className="d-none">E-mail</Label>
                <Input
                  placeholder="Email*"
                  type="email"
                  required
                  onChange={setEmail}
                  value={email}
                />
              </FormGroup>
              <Row className="mb-4">
                <Col md={6}>
                  <FormGroup className="">
                    <Label className="d-none">Password</Label>
                    <PasswordMask
                      placeholder="Password*"
                      required
                      onChange={setPassword}
                      value={password}
                      showButtonContent={<FontAwesomeIcon icon={faEye} />}
                      hideButtonContent={<FontAwesomeIcon icon={faEyeSlash} />}
                      buttonStyles={{ background: 'transparent', color: 'var(--primary)' }}
                    />
                  </FormGroup>
                  {password !== passwordConfirm && (
                    <div className="text-danger">Le password non coincidono</div>
                  )}
                </Col>
                <Col md={6}>
                  <FormGroup className="">
                    <Label className="d-none">Conferma Password</Label>
                    <PasswordMask
                      placeholder="Conferma Password*"
                      required
                      onChange={setPasswordConfirm}
                      value={passwordConfirm}
                      showButtonContent={<FontAwesomeIcon icon={faEye} />}
                      hideButtonContent={<FontAwesomeIcon icon={faEyeSlash} />}
                      buttonStyles={{ background: 'transparent', color: 'var(--primary)' }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div>
                <p className="small text-muted">
                  <input type="checkbox" checked disabled className="mr-2" />
                  Dichiaro di aver letto i {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a href="#" title="termini e condizioni">
                    Termini e Condizioni
                  </a>{' '}
                  di Easy Tax Assistant e l’informativa privacy ad essa allegata e di accettarne le
                  condizioni.
                </p>
                <p className="small text-muted">
                  <input type="checkbox" checked disabled className="mr-2" />
                  Ai sensi degli art. 1341 e 1342 c.c. accetto gli art. 3.4, 4.3, 4.6, 6.5, 12, 21.1
                  dei Termini e Condizioni di EasyTax Assistant.
                </p>
                <p className="small text-muted">
                  <ReactSwitch
                    className="mr-2"
                    uncheckedIcon={null}
                    checkedIcon={null}
                    height={15}
                    width={30}
                    checked={thirdParty}
                    onChange={setThirdParty}
                  />{' '}
                  Dichiaro di acconsentire al trattamento dei dati personali, per finalità di
                  marketing e ricevere comunicazioni commerciali mirate.
                </p>
              </div>
              <FormGroup className="d-flex justify-content-center mt-5">
                {!loading ||
                (firstName && lastName && email && password && password === passwordConfirm) ? (
                  <Button color="primary" className="btn-round" size="lg" type="submit">
                    Registrati {loading ? <i className="fas fa-spinner fa-spin" /> : null}
                  </Button>
                ) : (
                  <Button className="bg-gradient text-white rounded-custom" disabled size="lg">
                    Registrati {loading ? <i className="fas fa-spinner fa-spin" /> : null}
                  </Button>
                )}
              </FormGroup>
            </Form>
            <div className="d-flex justify-content-center">
              <Link to="/login" className="mx-2">
                Accedi
              </Link>
              <Link to="/forgot-password" className="mx-2">
                Recupera Password
              </Link>
            </div>
          </CardBody>
        </Card>
        <DownloadButtons />
      </Col>
    </div>
  );
}

RegistrationPage.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  setEmail: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};
