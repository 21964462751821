import React from 'react';
import { useInjectReducer } from 'redux-injectors';
import Component from './containers';
import { name, reducer } from './slice';

export default function() {
  useInjectReducer({
    key: name,
    reducer,
  });

  return <Component />;
}
