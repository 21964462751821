import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { Route, Switch, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from '@whitelabel/core';
import { name, reducer, actions, selectors } from './slice';
import procedureSaga from './saga';
import Procedure from './Procedure';
import './styles.scss';
import DocumentManagement from './DocumentManagement';
import Single from '../NewDocumentsSingle';
import ProcedureEdit from './ProcedureEdit';

export default function ProcedurePage() {
  useInjectReducer({
    key: name,
    reducer,
  });

  useInjectSaga({
    key: name,
    saga: procedureSaga,
  });

  const dispatch = useDispatch();
  const state = useSelector(selectors.selectState);
  const { id } = useParams();

  useEffect(() => {
    dispatch(actions.loadProcedure(id));
    dispatch(actions.loadMessages(id));
  }, [id]);

  if (state.loading) {
    return <Loading />;
  }

  return (
    <>
      <Route path="/pratiche/:id/preview/:docId" component={Single} />
      <Route path="/pratiche/:id/documenti/:categoryId/preview/:docId" component={Single} />
      <Switch>
        <Route path="/pratiche/:id/questionario/" component={ProcedureEdit} />
        <Route path="/pratiche/:id/documenti/:categoryId/" component={DocumentManagement} />
        <Route path="/pratiche/:id/" component={Procedure} />
      </Switch>
    </>
  );
}
