import { Redirect } from 'react-router-dom';
import React from 'react';

import DocumentsPage from './modules/NewDocuments';
import EditProfile from './modules/EditProfile';
import UploadDocument from './modules/UploadDocuments';
import Profile from './modules/Profile';
import DashboardPage from './modules/Dashboard';
import Procedures from './modules/Procedures';
import Procedure from './modules/Procedure';
import CreateProcedure from './modules/CreateProcedure';

// eslint-disable-next-line react/jsx-filename-extension
const ToDashboard = () => <Redirect to="/dashboard" />;

const routes = [
  {
    path: '/documents',
    name: 'Documents',
    component: DocumentsPage,
  },
  {
    path: '/reset-pass',
    name: 'ResetPass',
    component: EditProfile,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
  },
  {
    path: '/upload',
    name: 'Upload',
    component: UploadDocument,
  },
  {
    path: '/pratiche/crea/',
    name: 'Pratiche',
    component: CreateProcedure,
  },
  {
    path: '/pratiche/:id',
    name: 'Pratica',
    component: Procedure,
  },
  {
    path: '/pratiche/',
    name: 'Pratiche',
    component: Procedures,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardPage,
  },
  {
    path: '',
    name: 'Redirect',
    component: ToDashboard,
  },
];

export default routes;
