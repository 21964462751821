import React from 'react';
import ReactDOM from 'react-dom';

import { pdfjs } from 'react-pdf';
import { toast } from 'react-toastify';
import moment from 'moment';
import 'moment/locale/it';
import ReactGA from 'react-ga';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import './errorReporting';
import './polyfills/fill';

import App from './App';
import './styles/index.scss';

moment.locale('it');
pdfjs.GlobalWorkerOptions.workerSrc =
  process.env.NODE_ENV === 'production'
    ? `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
    : '/static/js/pdf.worker.min.js';

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING);
}

ReactDOM.render(<App />, document.getElementById('root'));
toast.configure();
