import { compose, withHandlers, withStateHandlers } from 'recompose';
import { sha256 } from 'crypto-hash';
import { toast } from 'react-toastify';
import { apiClient, APIError } from '@whitelabel/core';
import RegistrationPage from '../components/RegistrationPage';

const enhance = compose(
  withStateHandlers(
    {
      email: '',
      password: '',
      passwordConfirm: '',
      firstName: '',
      lastName: '',
      thirdParty: false,
      loading: false,
      submitted: false,
    },
    {
      setEmail: () => e => ({ email: e.target.value }),
      setPassword: () => e => ({ password: e.target.value }),
      setPasswordConfirm: () => e => ({ passwordConfirm: e.target.value }),
      setFirstName: () => e => ({ firstName: e.target.value }),
      setLastName: () => e => ({ lastName: e.target.value }),
      setLoading: () => loading => ({ loading }),
      setThirdParty: () => thirdParty => ({ thirdParty }),
      setSubmitted: () => submitted => ({ submitted }),
    },
  ),
  withHandlers({
    onSubmit: ({
      setLoading,
      setSubmitted,
      email,
      firstName,
      lastName,
      password,
      thirdParty,
    }) => async e => {
      e.preventDefault();
      setLoading(true);

      const hashedPassword = await sha256(password);
      try {
        await apiClient(
          '/users/guest/register/lite',
          {
            method: 'POST',
            body: {
              User: {
                Name: firstName,
                Surname: lastName,
                Email: email,
                Privacy: true,
                Gdpr: true,
                ThirdParty: thirdParty,
              },
              Password: hashedPassword,
            },
          },
          { generic: true },
        );

        setLoading(false);
        setSubmitted(true);
      } catch (err) {
        setLoading(false);

        if (err instanceof APIError) {
          if (err.payload.Result === 4) {
            toast.error("L'indirizzo email non è corretto");
          } else if (err.payload.Result === 13) {
            toast.error("L'indirizzo email è già registrato");
          } else {
            toast.error(
              'La richiesta non è andata a buon fine, controlla la connessione e riprova',
            );
          }
        } else {
          toast.error('La richiesta non è andata a buon fine, controlla la connessione e riprova');
        }
      }
    },
  }),
);

export default enhance(RegistrationPage);
