/* eslint-disable no-param-reassign */
import { createSelector, createSlice } from '@reduxjs/toolkit';

export const initialState = {
  list: null,
  loading: false,
  error: false,
  user: null,
  page: 1,
  totPages: 1,
  descending: true,
  key: 1,
  orderBy: null,
  amount: 0,
  count: 0,
  category: null,
  mainCategory: null,
  categoryKey: 0,
  dateSelector: {
    startDate: null,
    endDate: null,
  },
};

const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    startLoading(state, action) {
      state.loading = true;
      state.page = action.payload;
    },
    successLoading(state, action) {
      state.loading = false;
      state.list = action.payload.Documents;
      state.page = action.payload.Page;
      state.totPages = action.payload.TotPages;
      state.amount = action.payload.AmountSum;
      state.count = action.payload.TotDocuments;
    },
    errorLoading(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setCategory(state, action) {
      state.category = action.payload;
    },
    setDate(state, action) {
      state.dateSelector = action.payload;
    },
    setMainCategoryWithoutReset(state, action) {
      state.mainCategory = action.payload;
      state.categoryKey += 1;
    },
    setMainCategory(state, action) {
      state.mainCategory = action.payload;
      state.categoryKey += 1;
      state.category = null;
    },
    revalidate_success(state, action) {
      state.list = state.list.filter(doc => doc.Id !== action.payload.Id);
    },
    setShowAll(state, action) {
      state.showAll = action.payload;
    },
    setDescending(state, action) {
      state.descending = action.payload;
    },
    setOrderBy(state, action) {
      state.orderBy = action.payload;
    },
    // eslint-disable-next-line no-unused-vars
    reset(state) {
      state = { ...initialState };
    },
  },
});

export const { reducer, actions, name } = documentsSlice;

export const selectors = {
  getFiltersWithPage: createSelector(
    state => state && state[name],
    ({
      page,
      user,
      descending,
      orderBy,
      showAll,
      category,
      mainCategory,
      dateSelector: { endDate, startDate },
    }) => ({
      userId: user && user.Id,
      descending,
      orderBy,
      showAll,
      pageStart: page,
      categoryId: category && category.Id,
      mainCategoryId: mainCategory && mainCategory.Id,
      startDate,
      endDate,
    }),
  ),
};
