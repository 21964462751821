import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import React from 'react';
import moment from 'moment';
import { QuestionarioForm } from '@whitelabel/core';
import { useHistory } from 'react-router-dom';
import { selectors } from '../slice';

export default function ProcedureEdit() {
  const state = useSelector(selectors.selectState);
  const initialValues = useSelector(selectors.selectInitialData);
  const history = useHistory();

  return (
    <>
      <div className="d-flex mt-4 align-items-center mb-2">
        <div className="d-flex align-items-center">
          <Button
            onClick={() => history.push(`/pratiche/${state.id}/`)}
            className="mr-2"
            color="default"
          >
            <i className="fas fa-arrow-left" />
          </Button>
          <div>
            <h2 className="mb-0">Questionario</h2>
            <p className=" text-muted mb-0">
              Pratica {state.data.Name} del {moment(state.data.CreatedAt).format('DD/MM/YYYY')}
            </p>
          </div>
        </div>
        <div className="ml-auto" />
      </div>
      <div className="pb-5">
        <QuestionarioForm
          initialValues={initialValues}
          onSubmit={() => null}
          loading={state.updating}
          disabled
        />
      </div>
    </>
  );
}
