import { apiClient } from '@whitelabel/core';

export async function fetchDocument(id) {
  return apiClient(`documents/${id}`);
}

export async function deleteDocument(id) {
  return apiClient(`documents/${id}`, {
    method: 'DELETE',
  });
}

export async function updateDocument(id, body) {
  return apiClient(`documents/${id}`, {
    method: 'POST',
    body,
  });
}

export async function fetchCategories(searchParams = {}) {
  return apiClient(`data/v4/categories/`, { searchParams });
}
