import { apiClient } from '@whitelabel/core';

export async function fetchProcedure(id) {
  return apiClient(`practices/${id}/webapp`);
}

export async function fetchMessages(id, searchParams = {}) {
  return apiClient(`practices/${id}/messages`, {
    searchParams,
  });
}

export async function fetchDocuments({ id, categoryId }, search) {
  return apiClient(`practices/${id}/documents/webapp`, {
    searchParams: {
      ...search,
      categoryId,
    },
  });
}

export async function sendMessage(id, Message) {
  return apiClient(`practices/${id}/messages`, {
    body: { Message },
    method: 'POST',
  });
}

export async function searchDocuments(searchParams) {
  return apiClient(`documents/webapp`, { searchParams });
}

export async function associate(id, { documentId }) {
  return apiClient(`admin/practices/${id}/documents`, {
    body: {
      DocumentId: documentId,
    },
    method: 'POST',
  });
}

export async function removeDocument(id, documentId) {
  return apiClient(`admin/practices/${id}/documents/${documentId}`, {
    method: 'DELETE',
  });
}

export async function createPlaceholder(id, { type }) {
  return apiClient(`admin/practices/${id}/documents`, {
    body: {
      Type: type,
    },
    method: 'POST',
  });
}

export async function setMessagesRead(id) {
  return apiClient(`practices/${id}/messages/setread`, {
    method: 'POST',
  });
}

export async function updateProcedure(id, data) {
  return apiClient(`practices/${id}/webapp`, {
    body: data,
    method: 'POST',
  });
}

export async function updateUserdetailsGroup(sublist, data) {
  return apiClient(`users/details/${sublist}`, {
    body: data,
    method: 'POST',
  });
}

export async function getAllCategories(search = {}) {
  const resp = await apiClient(`data/v4/categories`, {
    searchParams: search,
  });
  return resp.Categories;
}

export async function getCategory(id = {}) {
  return apiClient(`data/v4/categories/${id}`);
}
