import { useSelector } from 'react-redux';
import { Button, Card, CardBody, UncontrolledCollapse } from 'reactstrap';
import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { EXCLUDE_FIELDS, FIELD_CONVERTER } from '../config';
import { selectors } from '../slice';

const dateRegex = new RegExp(/\d{4}-\d{2}-\d{2}/);

function render(field, fieldName = 'field') {
  if (typeof field === 'undefined' || field == null) {
    throw Error();
  }

  if (typeof field === 'boolean') {
    return field ? 'Si' : 'No';
  }

  if (Array.isArray(field)) {
    return [
      <Button color="primary" outline id={`toggle-${fieldName}`}>
        {field.length} <i className="fas fa-arrow-down" />
      </Button>,
      <UncontrolledCollapse className="ml-2 mt-2" toggler={`#toggle-${fieldName}`}>
        {field.map(o => (
          <div key={o.Id} className="mb-2 border p-2 mx-1">
            {render(o)}
          </div>
        ))}
      </UncontrolledCollapse>,
    ];
  }

  if (field instanceof Object) {
    return Object.keys(field)
      .filter(k => !EXCLUDE_FIELDS.includes(k))
      .map(k => {
        try {
          let result = null;
          if (Array.isArray(field[k])) {
            result = render(field[k], k);
          } else {
            result = render(field[k], k);
          }
          return (
            <div key={k} className="mb-1">
              <div className="text-left ml-1">
                <b>{FIELD_CONVERTER[k] || k}</b>
              </div>
              <div
                className={classNames('text-right', { 'border-bottom': !Array.isArray(result) })}
              >
                {result}
              </div>
            </div>
          );
        } catch (e) {
          return null;
        }
      });
  }

  if (typeof field === 'string' && dateRegex.test(field)) {
    return moment(field).format('DD/MM/YYYY');
  }

  return field;
}

export default function UserDetails() {
  const details = useSelector(selectors.selectUserDetails);

  return (
    <>
      <h5>Dati</h5>
      <Card>
        <CardBody>{details && render(details)}</CardBody>
      </Card>
    </>
  );
}
