/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import Card from 'reactstrap/es/Card';
import CardBody from 'reactstrap/es/CardBody';
import CardHeader from 'reactstrap/es/CardHeader';
import Button from 'reactstrap/es/Button';
import { format } from 'date-fns';
import { SimpleTable, usePagination, configs, Ellipsis, formatMoney } from '@whitelabel/core';
import * as classnames from 'classnames';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { Link, Route } from 'react-router-dom';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import Single from '../../NewDocumentsSingle';

const { ORDER_BY_DOCS } = configs;

export const columns = [
  {
    Header: 'Stato',
    accessor: 'Status',
    Cell: ({ value }) => (
      <div className="text-center">
        <i
          className={classnames('fas fa-circle', {
            'text-warning': value === 3,
            'text-success': value === 5,
            'text-danger': value === 6,
          })}
        />
      </div>
    ),
  },
  {
    Header: 'Nome documento',
    accessor: 'Name',
    Cell: ({ value, row: { original } }) => (
      <>
        <b>{original.UserName}</b>
        <br />
        {value}
      </>
    ),
  },
  {
    Header: 'Data',
    accessor: 'Date',
    Cell: ({ value }) => format(new Date(value), 'dd/MM/yyyy'),
  },
  {
    Header: 'Data Caricamento',
    accessor: 'CreatedAt',
    Cell: ({ value }) => format(new Date(value), 'dd/MM/yyyy HH:mm'),
  },
  {
    Header: 'Importo',
    accessor: 'Amount',
    Cell: ({ value }) => formatMoney(value),
  },
  {
    Header: 'Categoria di spesa',
    accessor: 'CategoryName',
    Cell: ({ value }) => <Ellipsis component="div">{value}</Ellipsis>,
  },
  {
    Header: '',
    accessor: 'Id',
    Cell: ({ value }) => (
      <Button tag={Link} color="primary" to={`/documents/${value}`}>
        Visualizza
      </Button>
    ),
  },
];

export default function Page({
  data,
  loading,
  page,
  totPages,
  loadPage,
  onChangeDescending,
  onChangeOrderBy,
  descending,
  amount,
  count,
  setCategory,
  category,
  loadCategories,
  macro,
  setMacro,
  macroOptions,
  catKey,
  dateSelector,
  onDatesChange,
  setFocusedInput,
  focusedInput,
}) {
  const pagination = usePagination({
    totPages,
    loadPage,
    page,
  });

  return (
    <>
      <div className="mt-3 d-flex">
        <div className="d-flex align-items-center flex-wrap">
          <Select
            placeholder="Macro categoria"
            value={macro}
            className="mr-1 mb-1"
            onChange={setMacro}
            styles={{ input: () => ({ minWidth: '8rem' }) }}
            options={macroOptions}
            getOptionLabel={opt => opt.Name}
            getOptionValue={opt => opt.Id}
            isClearable
          />
          <AsyncSelect
            className="mr-1 mb-1"
            loadOptions={loadCategories}
            getOptionLabel={opt => opt.Name}
            getOptionValue={opt => opt.Id}
            onChange={setCategory}
            isClearable
            defaultOptions
            placeholder="Categoria"
            key={catKey}
            value={category}
            styles={{ input: () => ({ minWidth: '7rem' }) }}
          />
          <DateRangePicker
            startDate={dateSelector.startDate && moment(dateSelector.startDate, 'YYYY-MM-DD')}
            endDate={dateSelector.endDate && moment(dateSelector.endDate, 'YYYY-MM-DD')}
            focusedInput={focusedInput}
            onDatesChange={({ endDate, startDate }) =>
              onDatesChange({
                startDate: startDate && moment(startDate).format('YYYY-MM-DD'),
                endDate: endDate && moment(endDate).format('YYYY-MM-DD'),
              })
            }
            onFocusChange={setFocusedInput}
            isOutsideRange={() => false}
            startDatePlaceholderText="Inizio"
            endDatePlaceholderText="Fine"
            noBorder
            showClearDates
            startDateId="startInput"
            endDateId="endInput"
          />
          <Select
            placeholder="Ordina per"
            options={ORDER_BY_DOCS}
            onChange={onChangeOrderBy}
            styles={{ input: () => ({ minWidth: '5rem' }) }}
          />
          <UncontrolledTooltip target="order" placement="top">
            {descending ? 'Discentente' : 'Ascendente'}
          </UncontrolledTooltip>
          <Button
            id="order"
            className="shadow ml-1 text-primary bg-white border-0"
            onClick={() => onChangeDescending(!descending)}
          >
            <i
              className={classnames('fas', {
                'fa-caret-down': descending,
                'fa-caret-up': !descending,
              })}
            />
          </Button>
        </div>
        <div className="ml-auto d-flex">
          <Card className="mx-1 bg-gradient text-white border-0">
            <CardBody className="py-1 px-3">
              <div className="d-flex align-items-center">
                <div>
                  <div className="h4 mb-1 font-weight-bold">
                    {formatMoney(amount, { format: '%v' })}
                  </div>
                  <div className="">Importo</div>
                </div>
                <i className="fas fa-euro-sign fa-2x ml-2" />
              </div>
            </CardBody>
          </Card>
          <Card className="mx-1 ml-2 bg-gradient text-white border-0">
            <CardBody className="py-1 px-2">
              <div className="d-flex align-items-center">
                <div>
                  <div className="h4 mb-1 font-weight-bold">{count}</div>
                  <div className="">Documenti</div>
                </div>
                <i className="fas fa-file fa-2x ml-2" />
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
      <Card className="mt-3 shadow-lg border-0">
        <CardHeader className="shadow h3 bg-primary rounded-bottom">Documenti</CardHeader>
        <CardBody className="p-0">
          <SimpleTable loading={loading} data={data || []} columns={columns} />
          {!loading && pagination}
        </CardBody>
      </Card>
      <Route path="/documents/:docId" component={Single} />
    </>
  );
}

Page.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      UserName: PropTypes.string.isRequired,
      Name: PropTypes.string.isRequired,
      CategoryName: PropTypes.string.isRequired,
      Amount: PropTypes.number.isRequired,
    }),
  ),
  loading: PropTypes.bool.isRequired,
};

Page.defaultProps = {
  data: [],
};
