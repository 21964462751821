// eslint-disable-next-line import/no-extraneous-dependencies
import { takeLatest, call, put } from '@redux-saga/core/effects';
import { APIError, Storage } from '@whitelabel/core';
import omit from 'lodash/omit';
import { toast } from 'react-toastify';
import history from '../../history';
import { APP_LOGOUT } from '../constants';
import { actions as userActions } from './user';
import * as apis from './apis';

function* runLogout() {
  Storage.remove('user', 'token');
  history.replace('/');
  yield;
}

function* updateUser(action) {
  try {
    const data = omit(action.payload, 'City');

    if (action.payload.City) {
      data.Common = {
        CadastralCode: action.payload.City.CadastralCode,
        Name: action.payload.City.Name,
        Province: action.payload.City.Province,
      };
    }

    yield call(apis.updateUser, data);
    yield put(userActions.loadUser(data));
    toast.success('Profilo aggiornato');
  } catch (e) {
    console.log(e);
    if (e instanceof APIError && e.payload.Exception === 'FiscalCode is not valid.\n') {
      toast.error('Codice fiscale non valido');
    } else {
      yield put(userActions.errorUpdate());
      toast.error("Errore nell'aggiornare il profilo!");
    }
  }
}

export default function* authSagas() {
  yield takeLatest(APP_LOGOUT, runLogout);
  yield takeLatest(userActions.updateUser, updateUser);
}
