import React, { useState } from 'react';
import { QuestionarioForm, YesNoField, Asterisk } from '@whitelabel/core';
import { Button, Card, CardBody, Col, Form, FormGroup, Label, Progress, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { actions, selectors } from '../Procedures/slice';

import step1 from '../../assets/compila1.svg';
import step2 from '../../assets/compila2.svg';
import step3 from '../../assets/compila3.svg';
import robottino from '../../assets/robottino.svg';

const TITLES = [
  'Compila il questionario',
  'Attendi la nostra chiamata',
  'Soddisfatti o rimborsati',
  'Rispondi alle domande',
];

const IMAGES = [step1, step2, step3];

const DESCS = [
  <p>
    <b>Compila</b> il questionario con <b>i tuoi dati e carica</b> tutta{' '}
    <b>la documentazione richiesta.</b>
    <br />
    La documentazione verrà analizzata e passata a un intermediario abilitato.
  </p>,
  <p>
    <b>Un intermediario abilitato ti contatterà per completare la pratica.</b>
    <br />
    Potrai firmare la documentazione necessaria rimanendo comodamente a casa.{' '}
  </p>,
  <p>
    <b>Il costo del servizio è di 29,99&euro;.</b>
    <br />
    Ma se con Easytax non ti facciamo risparmiare ottenere un risparmio di almeno 50&euro;,
    <br />
    il servizio non lo paghi.
  </p>,
];

export default function CreateProcedure() {
  const [isPIVA, setIsPIVA] = useState(null);
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useSelector(selectors.getState);

  const Step4 = (
    <div className="pb-4">
      <Form className="questionario mt-2">
        <FormGroup>
          <Label>
            Prima di cominciare: hai una P.IVA? <Asterisk />
          </Label>
          <YesNoField input={{ value: isPIVA, onChange: v => setIsPIVA(v) }} name="PIVA" />
        </FormGroup>
      </Form>
      {isPIVA === false && (
        <QuestionarioForm
          initialValues={{}}
          loading={state.loading}
          onSubmit={data => dispatch(actions.create(data))}
        />
      )}
    </div>
  );

  return (
    <>
      <Row className="mt-4">
        <Col md={8}>
          <Card>
            <CardBody>
              <div className="d-flex align-items-center mb-2">
                <h4 className="mb-0 mt-2">
                  <span className="text-primary">{step + 1}.</span> {TITLES[step]}
                </h4>
              </div>
              {step < 3 && (
                <div className="d-flex flex-column align-items-center">
                  <img src={IMAGES[step]} className="" alt="" style={{ maxHeight: '350px' }} />
                  <div className="text-center">{DESCS[step]}</div>
                </div>
              )}
              {step === 3 && Step4}
            </CardBody>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <CardBody className="px-0 d-flex justify-content-between">
              <div className="mx-3 flex-grow-1">
                <h5>Avanzamento</h5>
                <Progress color="primary" value={step < 3 ? step * 10 + 10 : 50} />
              </div>
              <img src={robottino} style={{ height: '50px' }} alt="easytax assistant" />
            </CardBody>
          </Card>
          <Card className="mt-4">
            <CardBody>
              {step < 3 && (
                <Button className="btn-round w-100 mb-4" onClick={() => setStep(step + 1)}>
                  Continua <i className="fas fa-arrow-right" />
                </Button>
              )}
              <Button className="w-100 bg-gray-500 border-0" onClick={() => history.goBack()}>
                <i className="fas fa-arrow-left" /> Annulla
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}
