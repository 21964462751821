import { compose, withHandlers, withProps, withStateHandlers, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import find from 'lodash/find';
import { apiClient, NotAuthenticatedError } from '@whitelabel/core';
import { toast } from 'react-toastify';
import DashboardPage from '../components/DashboardPage';
import { APP_LOGOUT } from '../../../store/constants';

const enhance = compose(
  connect(
    state => ({
      macroOptions: state.macrocategories.list,
    }),
    dispatch => ({
      logout: () => dispatch({ type: APP_LOGOUT }),
    }),
  ),
  withStateHandlers(
    {
      selectUploaded: false,
      filters: {},
      loadingDocs: false,
      loadingAggr: false,
      loadingTopCat: false,
      macro: null,
      category: null,
      topCat: [],
      resetKey: 0,
      docs: {
        Total: 0,
        Valid: 0,
        Rejected: 0,
        Pending: 0,
      },
      aggregated: new Array(12).fill({
        DocCount: 0,
        UploadedCount: 0,
        TotalUploadedAmount: 0,
        TotalAmount: 0,
      }),
      generic: {
        LastAccess: new Date(),
        RegistrationDate: new Date(),
      },
    },
    {
      setLoadingDocs: () => value => ({
        loadingDocs: value,
      }),
      setLoadingAggr: () => value => ({
        loadingAggr: value,
      }),
      setDocs: () => value => ({
        docs: value,
      }),
      setGeneric: () => value => ({
        generic: value,
      }),
      setAggregated: () => value => ({
        aggregated: value,
      }),
      setSelectUpload: () => value => ({
        selectUploaded: value,
      }),
      setFilters: state => value => ({
        filters: {
          ...state.filters,
          ...value,
        },
      }),
      forceResetKey: state => () => ({
        resetKey: state.resetKey + 1,
      }),
      setLoadingTopCat: () => value => ({
        loadingTopCat: value,
      }),
      setTopCat: () => value => ({
        topCat: value,
      }),
      _setCategory: () => category => ({ category }),
      _setMacro: () => macro => ({ macro }),
    },
  ),
  withHandlers({
    loadDocs: ({ setDocs, filters, setLoadingDocs, logout }) => async (newFilters = {}) => {
      setLoadingDocs(true);
      try {
        const response = await apiClient('/stats/docs', {
          searchParams: {
            ...filters,
            ...newFilters,
          },
        });
        setDocs(response);
      } catch (e) {
        if (e instanceof NotAuthenticatedError) {
          toast.error('La sessione è scaduta');
          logout();
        } else {
          toast.error("Si è verificato un'errore");
        }
      }
      setLoadingDocs(false);
    },
    loadAggr: ({ setAggregated, filters, setLoadingAggr, logout }) => async (newFilters = {}) => {
      setLoadingAggr(true);
      try {
        const response = await apiClient('/stats/aggregatedocs', {
          searchParams: {
            ...filters,
            ...newFilters,
          },
        });
        setAggregated(response);
      } catch (e) {
        if (e instanceof NotAuthenticatedError) {
          toast.error('La sessione è scaduta');
          logout();
        } else {
          toast.error("Si è verificato un'errore");
        }
      }
      setLoadingAggr(false);
    },
    loadGen: ({ setGeneric, filters, logout }) => async (newFilters = {}) => {
      try {
        const response = await apiClient('/stats/generic', {
          searchParams: {
            ...filters,
            ...newFilters,
          },
        });
        setGeneric(response);
      } catch (e) {
        if (e instanceof NotAuthenticatedError) {
          toast.error('La sessione è scaduta');
          logout();
        } else {
          toast.error("Si è verificato un'errore");
        }
      }
    },
    loadTopCat: ({ setTopCat, filters, setLoadingTopCat, logout }) => async (newFilters = {}) => {
      setLoadingTopCat(true);
      try {
        const response = await apiClient('/stats/topcategories', {
          searchParams: {
            ...filters,
            ...newFilters,
          },
        });
        setTopCat(response);
      } catch (e) {
        if (e instanceof NotAuthenticatedError) {
          toast.error('La sessione è scaduta');
          logout();
        } else {
          toast.error("Si è verificato un'errore");
        }
      }
      setLoadingTopCat(false);
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.loadDocs();
      this.props.loadAggr();
      this.props.loadGen();
      this.props.loadTopCat();
    },
  }),

  withProps(({ docs, aggregated, selectUploaded, filters, macroOptions }) => {
    const maxCount = Math.max(
      ...aggregated.map(v => (selectUploaded ? v.UploadedCount : v.DocCount)),
    );
    const maxAmount = Math.max(
      ...aggregated.map(v => (selectUploaded ? v.TotalUploadedAmount || 0 : v.TotalAmount || 0)),
    );

    /* compute the normalization scale */
    const scale = maxAmount === 0 && maxCount === maxAmount ? 0 : maxAmount / maxCount;

    const mainCat = find(macroOptions, c => c.Id === filters.mainCategoryId);
    let categories = [];
    if (mainCat) {
      categories = mainCat.SubCategories;
    } else if (macroOptions) {
      categories = macroOptions.map(m => m.SubCategories).flat();
    }

    return {
      scale,
      max: maxCount,
      countData: aggregated.map((v, i) => ({
        x: i,
        y: selectUploaded ? v.UploadedCount || 0 : v.DocCount || 0,
      })),
      amountData:
        scale > 0
          ? aggregated.map((v, i) => ({
              x: i,
              y: (selectUploaded ? v.TotalUploadedAmount || 0 : v.TotalAmount || 0) / scale,
            }))
          : aggregated.map((v, i) => ({ x: i, y: 0 })),
      docsData: [
        {
          angle: docs.Valid,
          category: 'Validati',
          value: docs.Valid.toString(),
          color: 'url(#greenGradient)',
        },
        {
          angle: docs.Rejected,
          category: 'Scartati',
          value: docs.Rejected.toString(),
          color: 'url(#redGradient)',
        },
        {
          angle: docs.Pending,
          category: 'Da Validare',
          value: docs.Pending.toString(),
          color: 'url(#yellowGradient)',
        },
        {
          angle: docs.Deleted && docs.Deleted > 0 ? docs.Deleted : 0,
          category: 'Eliminati',
          value: docs.Deleted && docs.Deleted > 0 ? docs.Deleted.toString() : 0,
          color: 'url(#blueGradient)',
        },
      ].filter(v => v.angle > 0),
      categories,
    };
  }),
  withHandlers({
    setYear: ({ setFilters, loadDocs, loadAggr, loadTopCat }) => year => {
      setFilters({ year });
      loadDocs({ year });
      loadAggr({ year });
      loadTopCat({ year });
    },
    setCategory: ({
      setFilters,
      loadDocs,
      loadAggr,
      loadTopCat,
      _setCategory,
      _setMacro,
    }) => category => {
      const categoryId = category ? category.Id : null;
      const macroCategoryId = category ? category.MainCategoryId : null;
      _setCategory(category);
      _setMacro(macroCategoryId);
      setFilters({ categoryId, macroCategoryId });
      loadDocs({ categoryId, macroCategoryId });
      loadAggr({ categoryId, macroCategoryId });
      loadTopCat({ categoryId, macroCategoryId });
    },
    setMacro: ({
      setFilters,
      loadDocs,
      loadAggr,
      forceResetKey,
      loadTopCat,
      _setMacro,
    }) => mainCategoryId => {
      forceResetKey();
      _setMacro(mainCategoryId);
      setFilters({ mainCategoryId, categoryId: null });
      loadDocs({ mainCategoryId, categoryId: null });
      loadAggr({ mainCategoryId, categoryId: null });
      loadTopCat({ mainCategoryId, categoryId: null });
    },
  }),
);

export default enhance(DashboardPage);
