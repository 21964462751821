import { apiClient } from '@whitelabel/core';

// eslint-disable-next-line import/prefer-default-export
export async function fetchDocuments(searchParams = {}) {
  return apiClient(`documents/webapp`, { searchParams });
}

export async function fetchCategories(searchParams = {}) {
  return apiClient(`data/v4/categories`, { searchParams });
}
