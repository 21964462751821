import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { branch, lifecycle, renderComponent, withState, compose } from 'recompose';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import ReactGA from 'react-ga';
import { ClientProvider, ProtectedRoute, Loading, Storage, apiClient } from '@whitelabel/core';

import history from './history';
import DefaultLayout from './modules/DefaultLayout';
import LoginPage from './modules/Login';
import ForgotPasswordPage from './modules/ForgotPassword';
import storeBuilder from './store';
import { APP_LOGOUT } from './store/constants';
import RegistrationPage from './modules/Registration';
import Landing730 from './modules/Landing730';

async function hydratateStore() {
  const initial = {};
  const token = Storage.retrieve('token');
  if (token) {
    try {
      const body = await apiClient(`users`);
      initial.token = Storage.retrieve('token');
      initial.user = {
        ...Storage.retrieve('user'),
        ...body,
      };
      if (process.env.NODE_ENV === 'production') {
        ReactGA.set({
          userId: body.Id,
        });
      }
    } catch (e) {
      console.log(e);
      Storage.remove('user', 'token');
    }
  }

  return storeBuilder(initial);
}

function App({ store }) {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      return history.listen(location => {
        ReactGA.set({
          page: location.pathname,
        });
        ReactGA.pageview(location.pathname);
      });
    }
    return undefined;
  }, []);

  const logout = () => store.dispatch({ type: APP_LOGOUT });
  return (
    <Provider store={store}>
      <Router history={history}>
        <ClientProvider sendLogout={logout}>
          <Switch>
            <Route exact path="/landing730" name="Landing 730" component={Landing730} />
            <Route exact path="/login" name="Login Page" component={LoginPage} />
            <Route exact path="/registration" name="Registration" component={RegistrationPage} />
            <Route
              exact
              path="/forgot-password"
              name="Forgot Password"
              component={ForgotPasswordPage}
            />
            <ProtectedRoute path="/" name="Home" component={DefaultLayout} />
            <Route component={() => <Redirect to="/" />} />
          </Switch>
          <ToastContainer />
        </ClientProvider>
      </Router>
    </Provider>
  );
}

const enhance = compose(
  withState('store', 'setStore', null),
  lifecycle({
    componentDidMount() {
      hydratateStore().then(store => this.props.setStore(store));
    },
  }),
  branch(
    ({ store }) => !store,
    renderComponent(() => <Loading fullPage vertical id="loadingPage" />),
  ),
);

export default enhance(App);

App.propTypes = {
  store: PropTypes.shape({
    dispatch: PropTypes.func,
  }),
};

App.defaultProps = {
  store: null,
};
