import PropTypes from 'prop-types';
import React from 'react';
import CardBody from 'reactstrap/es/CardBody';
import Card from 'reactstrap/es/Card';
import Label from 'reactstrap/es/Label';
import FormGroup from 'reactstrap/es/FormGroup';
import Input from 'reactstrap/es/Input';
import Form from 'reactstrap/es/Form';
import Button from 'reactstrap/es/Button';
import Col from 'reactstrap/es/Col';
import { Link } from 'react-router-dom';
import { Field } from 'redux-form';

export default function ForgotPasswordPage({ handleSubmit }) {
  return (
    <div
      className="d-flex justify-content-center align-items-center bg-login-background"
      style={{ height: '100vh' }}
    >
      <Col md={4}>
        <Card className="border-0">
          <CardBody className="px-5">
            <p className="mt-3">
              Inserisci il tuo indirizzo email per ottenere un link tramite cui resettare la tua
              password
            </p>
            <Form className="md-form" onSubmit={handleSubmit}>
              <FormGroup className="mt-2 mb-4">
                <Label className="d-none">E-mail</Label>
                <Input
                  tag={Field}
                  name="email"
                  placeholder="E-mail"
                  type="email"
                  component="input"
                  required
                />
              </FormGroup>
              <FormGroup className="d-flex justify-content-center mt-5">
                <Button color="primary" className="btn-round">
                  Resetta
                </Button>
              </FormGroup>
            </Form>
            <div className="d-flex justify-content-center">
              <Button color="secondary" tag={Link} to="/login" className="btn-round bg-transparent">
                Indietro
              </Button>
            </div>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
}

ForgotPasswordPage.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};
