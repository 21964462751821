/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const macrocategoriesInitialState = {
  loading: true,
  list: null,
  error: false,
};

const macrocategoriesSlice = createSlice({
  name: 'macrocategories',
  initialState: macrocategoriesInitialState,
  reducers: {
    loadStart(state) {
      state.loading = true;
      state.error = false;
    },
    loadSuccess(state, action) {
      state.loading = false;
      state.list = action.payload.Categories;
    },
  },
});

export const { actions } = macrocategoriesSlice;
export default macrocategoriesSlice.reducer;
