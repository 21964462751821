import React from 'react';
import * as router from 'react-router-dom';
import { Container } from 'reactstrap';

import { AppHeader, AppSidebar, AppSidebarFooter, AppSidebarHeader } from '@coreui/react';

import { SidebarNav } from '@whitelabel/core';
import navigation from '../../../navigation';
import routes from '../../../routes';
import DefaultHeader from './DefaultHeader';

const { Route, Switch } = router;

function DefaultLayout(props) {
  return (
    <div className="app">
      <AppHeader fixed>
        <DefaultHeader {...props} />
      </AppHeader>
      <div className="app-body">
        <AppSidebar fixed display="lg">
          <AppSidebarHeader />
          <SidebarNav navConfig={navigation()} router={router} />
          <AppSidebarFooter />
        </AppSidebar>
        <main className="main">
          <Container fluid>
            <Switch>
              {routes.map(route =>
                route.component ? (
                  <Route
                    key={route.name}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={prs => <route.component {...prs} />}
                  />
                ) : null,
              )}
            </Switch>
          </Container>
        </main>
      </div>
    </div>
  );
}

export default DefaultLayout;
