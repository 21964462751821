/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import { Field, formValueSelector } from 'redux-form';
import { Button, Card, CardBody, Input, Label, FormGroup, Form, Spinner } from 'reactstrap';
import moment from 'moment';
import {
  RFCurrencyInput,
  RFDatePicker,
  FiscalCode,
  CUInputGroup,
  RFSelectAsync,
  configs,
  validation,
} from '@whitelabel/core';

import { ANCHOR_RIGHT } from 'react-dates/esm/constants';
import { useSelector } from 'react-redux';

const { NOTES_OPTIONS, NOTES_TYPES } = configs;
const { required } = validation;

function DocumentUpdateForm({
  handleSubmit,
  document,
  macro,
  currentMain,
  loadCategories,
  isCU,
  resetCategory,
  deleteDocument,
  loading,
}) {
  const selector = formValueSelector('updateDocument');
  const notesValue = useSelector(state => selector(state, 'Notes'));
  const categoryValue = useSelector(state => selector(state, 'Category'));

  return (
    <Card style={{ maxHeight: 'calc(100vh - 12rem)', overflowY: 'auto' }}>
      <CardBody>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>Macro Categoria</Label>
            <Input
              type="select"
              placeholder=""
              tag={Field}
              name="MainCategoryId"
              onChange={resetCategory}
              component="select"
            >
              {macro &&
                macro.map(m => (
                  <option key={m.Id} value={m.Id}>
                    {m.Name}
                  </option>
                ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>Categoria di Spesa</Label>
            <Field
              name="Category"
              key={currentMain}
              component={RFSelectAsync}
              defaultOptions
              loadOptions={loadCategories}
              getOptionLabel={opt => opt.Name}
              getOptionValue={opt => opt.Id}
            />
          </FormGroup>
          {!isCU && (
            <>
              <FormGroup>
                <Label>Importo totale</Label>
                <Field name="Amount" required component={RFCurrencyInput} allowNegative />
              </FormGroup>
              <Field
                name="Date"
                label="Data del documento"
                component={RFDatePicker}
                format={value => (value ? moment(value, 'YYYY-MM-DDTHH:mm:ss') : null)}
                normalize={value => (value ? moment(value).format('YYYY-MM-DDTHH:mm:ss') : null)}
                validate={[required]}
                required
                override={{
                  withPortal: false,
                  appendToBody: true,
                  anchorDirection: ANCHOR_RIGHT,
                }}
              />
              <FormGroup>
                <Label>Codice Fiscale</Label>
                <Field
                  name="FiscalCode"
                  label="Codice Fiscale"
                  component={FiscalCode}
                  document={document}
                />
              </FormGroup>
            </>
          )}
          {isCU && (
            <Field
              name="Cu"
              component={CUInputGroup}
              document={document}
              category={categoryValue ? categoryValue.Id : document.CategoryId}
              disabled
            />
          )}
          <FormGroup>
            <Label>Note Preimpostate</Label>
            <Input tag={Field} name="Notes" component="select" disabled>
              <option value="">Nessuna</option>
              {NOTES_OPTIONS.map(note => (
                <option key={note}>{note}</option>
              ))}
            </Input>
            {notesValue === NOTES_TYPES.ALTRO && (
              <Input
                tag={Field}
                className="mt-2"
                name="OtherNotes"
                component="textarea"
                required
                disabled
                placeholder="Specifica le note"
                maxLength={250}
              />
            )}
          </FormGroup>
          <FormGroup>
            <Label>Note Operatore</Label>
            <Input tag={Field} name="OperatorNotes" component="textarea" maxLength={500} disabled />
          </FormGroup>
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <Button className="btn-round my-1" color="primary" disabled={loading}>
              Modifica {loading && <Spinner className="ml-2" size="sm" />}
            </Button>
            <Button onClick={deleteDocument} color="danger" className="my-1" disabled={loading}>
              Elimina {loading && <Spinner className="ml-2" size="sm" />}
            </Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
}

export default DocumentUpdateForm;

DocumentUpdateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  document: PropTypes.shape({
    CategoryId: PropTypes.string,
  }),
};

DocumentUpdateForm.defaultProps = {
  document: null,
};
