import { apiClient } from '@whitelabel/core';

export async function fetchProcedures(searchParams = {}) {
  return apiClient(`practices/webapp`, {
    searchParams,
  });
}

export async function createProcedure(body) {
  return apiClient(`practices/webapp`, {
    body,
    method: 'POST',
  });
}

export async function updateProcedure(data) {
  return apiClient(`users/details/webapp`, {
    body: data,
    method: 'POST',
  });
}

export async function updateUserdetailsGroup(sublist, data) {
  return apiClient(`users/details/${sublist}`, {
    body: data,
    method: 'POST',
  });
}
