export const EXCLUDE_FIELDS = [
  'Id',
  'FigliACaricoCount',
  'FamiglACaricoCount',
  'UserDetailsId',
  'AmmSocialiTypeId',
];

export const FIELD_CONVERTER = {
  StatoCivile: 'Stato Civile',
  AssegnoManten: 'Assegno di mantenimento',
  PensioneRev: 'Pensione di reversibilità',
  Invalidita: 'Invalidità',
  NuovaResidenza: 'Nuova Residenza',
  VecchioIndirizzo: 'Vecchio Indirizzo',
  DataCambioResidenza: 'Data cambio residenza',
  FigliACarico: 'Figli a carico',
  FamiglACarico: 'Famigliari a carico',
  OccupazType: 'Tipo di Occupazione',
  ScadenzaDeterminato: 'Scadenza Determinato',
  AmmSociali: 'Ammortizzatori Sociali',
  ScadenzaSussidio: 'Scadenza Sussidio',
  PensioniEstero: "Pensioni all'estero",
  CodiceFiscale: 'Codice Fiscale',
  Children: 'Figli a carico',
  Disabilita: 'Disabilità',
  RedditoBasso: 'Reddito basso',
  RedditiEstero: 'Redditi Estero',
  SpesePerDisabilita: 'Spese per disabilità',
  PropImmobili: 'Proprietario di immobili',
  FamilyMembers: 'Famigliari',
  StessoNucleo: 'Stesso Nucleo',
  MutuoType: 'Tipo Mutuo',
  MutuoRinegoziato: 'Mutuo Rinegoziato',
  MutuoSurrogato: 'Mutuo Surrogato',
  MutuoTrasfResidenza: 'Mutuo Trasferimento Residenza',
  Homes: 'Case',
  InAffitto: 'In affitto',
  CedolareSecca: 'Cedolare secca',
  CedolareSeccaType: 'Tipologia cedolare secca',
  GiorniAffitto: 'Giorni in affitto',
  Lands: 'Terreni',
  SpeseSanitarie: 'Spese Sanitarie',
  CassaAssSanitaria: 'Cassa Assistenza Sanitaria',
  AmmSocialiTypeName: 'Ammortizzatore Sociale',
  DichAnnoScorso: "Ha fatto la dichiarazione l'anno scorso",
  DichAnniPrecedenti: 'Ha mai fatto la dichiarazione',
  DichCongiunta: 'Dichiarazione congiunta',
  DelegaCUInps: 'Delega a scaricare le CU da INPS',
  FiscalCodeUser: 'Codice Fiscale',
  UserName: 'Nome e Cognome',
  Email: 'Email',
  PhoneNumber: 'Numero di telefono',
  UserRegion: 'Regione',
  UserProvince: 'Provincia',
  UserCity: 'Città',
};

export const STATUS = {
  Aperta: 'Aperta',
  InLavorazione: 'In Lavorazione',
  ChiusaPositivo: 'Chiusa Positivo',
  ChiusaNegativo: 'Chiusa Negativo',
};

export const STATUS_OPTIONS = Object.keys(STATUS).map(k => ({
  label: STATUS[k],
  value: k,
}));
