import { compose, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { sha256 } from 'crypto-hash';
import { apiClient, APIError } from '@whitelabel/core';
import LoginPage from '../components/LoginPage';
import { login } from '../../../store/auth/actions';

const enhance = compose(
  connect(
    () => ({}),
    dispatch => ({
      doLogin: login(dispatch),
    }),
  ),
  withState('state', 'setState', {
    email: '',
    password: '',
    loading: false,
  }),
  withHandlers({
    setEmail: ({ setState, state }) => e => setState({ ...state, email: e.target.value }),
    setPassword: ({ setState, state }) => e => setState({ ...state, password: e.target.value }),
    setTFA: ({ setState, state }) => e => setState({ ...state, tfa: e.target.value }),
    onSubmit: ({ state, setState, doLogin, history }) => async e => {
      e.preventDefault();
      setState({ ...state, loading: true });

      const hashedPassword = await sha256(state.password);
      const url = 'users/guest/login';
      const data = {
        Email: state.email,
        Password: hashedPassword,
        DeviceId: navigator.userAgent,
        Platform: 'WEB',
      };

      try {
        const result = await apiClient(url, {
          method: 'POST',
          body: data,
        });

        doLogin(
          result.Token,
          {
            id: result.Id,
            email: result.Email,
            ...result,
          },
          () => {
            history.replace('/');
          },
        );
      } catch (err) {
        if (err instanceof APIError && err.payload.Result === 11) {
          setState({ ...state, loading: false, password: '' });
          toast.error('La password è errata');
        } else {
          console.log(err);
          setState({ ...state, loading: false, password: '' });
          toast.error("Si è verificato un'errore");
        }
      }
    },
  }),
);

export default enhance(LoginPage);
