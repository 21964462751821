import { reducer as formReducer } from 'redux-form';
import createRootReducer from './root';
import userReducer from './auth/user';
import tokenReducer from './auth/token';
import categoriesReducer from './categories/reducer';
import macrocategoriesReducer from './macrocategories/reducer';
import { reducer as documentsReducer } from '../modules/NewDocuments/slice';
import { reducer as proceduresReducer } from '../modules/Procedures/slice';

const reducers = {
  user: userReducer,
  token: tokenReducer,
  categories: categoriesReducer,
  documents: documentsReducer,
  macrocategories: macrocategoriesReducer,
  form: formReducer,
  procedures: proceduresReducer,
};

export default createRootReducer(reducers);
