// eslint-disable-next-line import/no-extraneous-dependencies
import { takeEvery, call, put, select } from '@redux-saga/core/effects';
import { toast } from 'react-toastify';
// import { utils } from '@whitelabel/core';
// eslint-disable-next-line import/named
// import moment from 'moment';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import { actions, selectors } from './slice';
import * as apis from './apis';
import history from '../../history';

function* loadProcedures() {
  const filters = yield select(selectors.getFiltersWithPage);
  try {
    const data = yield call(apis.fetchProcedures, filters);
    yield put(actions.loadSuccess(data));
  } catch (e) {
    console.log(e);
    toast.error("Errore nell'ottenere la lista di pratiche");
  }
}

function* startLoading(action) {
  if (
    action.type === actions.setDateSelector.type &&
    !action.payload.dateStart &&
    !action.payload.dateEnd
  ) {
    return;
  }
  yield put(actions.load(1));
}

function mapAndOmit(array) {
  return array.map(o => omit(o, ['Id', 'UserDetailsId']));
}

function* createProcedure(action) {
  const data = pick(action.payload, [
    'DichAnnoScorso',
    'DichAnniPrecedenti',
    'DichCongiunta',
    'DelegaCUInps',
  ]);
  const userData = omit(action.payload.UserDetails, [
    'Children',
    'Lands',
    'FamilyMembers',
    'Homes',
    'AmmSocialiType',
  ]);

  if (action.payload.UserDetails) {
    userData.AmmSocialiTypeId = userData.AmmSocialiType ? userData.AmmSocialiType.Id : null;

    userData.CadastralCode = action.payload.UserCity && action.payload.UserCity.CadastralCode;
  }

  console.log(userData, action.payload);

  try {
    yield call(apis.createProcedure, data);
    if (action.payload.UserDetails) {
      yield call(apis.updateProcedure, userData);
      if (action.payload.UserDetails.Children) {
        yield call(
          apis.updateUserdetailsGroup,
          'children',
          mapAndOmit(action.payload.UserDetails.Children),
        );
      }
      if (action.payload.UserDetails.Lands) {
        yield call(
          apis.updateUserdetailsGroup,
          'lands',
          mapAndOmit(action.payload.UserDetails.Lands),
        );
      }
      if (action.payload.UserDetails.FamilyMembers) {
        yield call(
          apis.updateUserdetailsGroup,
          'familymembers',
          mapAndOmit(action.payload.UserDetails.FamilyMembers),
        );
      }
      if (action.payload.UserDetails.Homes) {
        yield call(
          apis.updateUserdetailsGroup,
          'homes',
          mapAndOmit(action.payload.UserDetails.Homes),
        );
      }
    }
    yield put(actions.load(1));
    history.push('/pratiche/');
  } catch (e) {
    console.log(e);
    toast.error('Errore nel creare la pratica');
  }
}

export default function* sagas() {
  yield takeEvery(actions.load.type, loadProcedures);
  yield takeEvery(actions.reset.type, startLoading);
  yield takeEvery(actions.setDescending.type, startLoading);
  yield takeEvery(actions.setShowAll.type, startLoading);
  yield takeEvery(actions.setOrderBy.type, startLoading);
  yield takeEvery(actions.setUser.type, startLoading);
  yield takeEvery(actions.setDateSelector.type, startLoading);
  yield takeEvery(actions.setStatus.type, startLoading);
  yield takeEvery(actions.create.type, createProcedure);
}
