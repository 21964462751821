import React, { useEffect } from 'react';
import { Card, Col, Modal, ModalBody, ModalHeader, Row, CardBody, Button } from 'reactstrap';
import { AttachmentViewer, Loading } from '@whitelabel/core';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import DocumentUpdateForm from '../UpdateDocumentForm';
import { actions, selectors } from './slice';
import { fetchCategories } from './apis';

export default function Single() {
  const history = useHistory();
  const { docId } = useParams();
  const dispatch = useDispatch();
  const { data: doc, loading, current, deleted, saving } = useSelector(selectors.selectState);

  const toggleModal = () => {
    history.goBack();
  };

  useEffect(() => {
    dispatch(actions.startLoading(docId));
  }, [docId]);

  const runDelete = async () => {
    const confirm = await Swal.fire({
      title: 'Sei sicuro?',
      text: 'Questa azione è irreversibile',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Elimina',
      cancelButtonText: 'No',
      reverseButtons: true,
    });
    if (confirm.value) {
      dispatch(actions.deleteDocument());
    }
  };

  const update = data => {
    dispatch(actions.updateDocument(data));
  };

  const getCategoriesList = async (text, mainCategoryId) => {
    const cats = await fetchCategories({
      query: text,
      mainCategoryId,
    });

    return cats.Categories;
  };

  return (
    <Modal isOpen={current} id="document-modal-preview" toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Documento {doc ? doc.Name : ''}</ModalHeader>
      <ModalBody className="p-0">
        {loading && <Loading />}
        {!loading && deleted && (
          <div className="d-flex justify-content-center p-5 align-items-center flex-column">
            <h2>Documento Rimosso</h2>
            <Button onClick={toggleModal}>Chiudi</Button>
          </div>
        )}
        {!loading && !deleted && (
          <Row>
            <Col key="attachment" md={9}>
              <Card className="shadow-lg border-0">
                <CardBody className="p-0">
                  <AttachmentViewer files={doc.Files} />
                </CardBody>
              </Card>
            </Col>
            <Col key="form" md={3}>
              <DocumentUpdateForm
                deleteDocument={runDelete}
                document={doc}
                loading={saving}
                onSubmit={update}
                Id={doc.Id}
                initialValues={doc}
                getCategoriesList={getCategoriesList}
              />
            </Col>
          </Row>
        )}
      </ModalBody>
    </Modal>
  );
}
