import { useSelector } from 'react-redux';
import { Row, Col, Button, Badge } from 'reactstrap';
import React from 'react';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import { selectors } from './slice';

import Chat from './Chat';
import UserDetails from './UserDetails';
import { STATUS } from './config';
import Categories from './Categories';

export default function Procedure() {
  const state = useSelector(selectors.selectState);
  const history = useHistory();

  return (
    <>
      <div className="d-flex mt-4 align-items-center mb-2">
        <div className="d-flex align-items-center">
          <Button onClick={() => history.push('/pratiche/')} className="mr-2" color="default">
            <i className="fas fa-arrow-left" />
          </Button>
          <h2 className="mb-0">
            Pratica {state.data.Name} del {moment(state.data.CreatedAt).format('DD/MM/YYYY')}
          </h2>
          <Badge className="ml-2">{STATUS[state.data.Status]}</Badge>
        </div>
        <div className="ml-auto">
          <Link to="questionario/">
            <Button color="primary" className="ml-2">
              Questionario
            </Button>
          </Link>
        </div>
      </div>

      <Row>
        <Col md={4}>
          <UserDetails />
        </Col>
        <Col md={4}>
          <Categories />
        </Col>
        <Col md={4}>
          <Chat />
        </Col>
      </Row>
    </>
  );
}
