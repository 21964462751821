import { combineReducers } from 'redux';
import { APP_LOGOUT } from './constants';

/**
 * create a redux wrapper reducer to allow the complete state wipe out
 * @param appReducer
 * @return {function(*, *=): *}
 */
export default function createRootReducer(reducers) {
  return injected => {
    const appReducer = combineReducers({
      ...reducers,
      ...injected,
    });

    return function rootReducer(state, action) {
      let nextState = state;
      if (action.type === APP_LOGOUT) {
        nextState = undefined;
      }

      return appReducer(nextState, action);
    };
  };
}
