import { RSAA } from 'redux-api-middleware';
import { Storage } from '@whitelabel/core';
import { LOAD_TOKEN, APP_LOGOUT } from '../constants';
import { actions as userActions } from './user';

/**
 * action creator for logout
 * @param dispatch
 * @return {Function}
 */
export function appLogout(dispatch) {
  return () => {
    dispatch({
      [RSAA]: {
        endpoint: 'users/logout',
        method: 'POST',
        params: {},
        types: ['app/empty', APP_LOGOUT, 'app/empty'],
      },
    });
  };
}

/**
 * action creator to set token
 * @param token
 * @return {{type: *, token: *}}
 */
export function setToken(token) {
  return {
    type: LOAD_TOKEN,
    token,
  };
}

/**
 * action creator to login
 * @param dispatch
 * @return {Function}
 */
export function login(dispatch) {
  return (token, user, cb) => {
    Storage.persist('token', token);
    Storage.persist('user', user);
    dispatch(setToken(token));
    dispatch(userActions.loadUser(user));
    cb();
  };
}
