import thunk from 'redux-thunk';
import { apiMiddleware } from 'redux-api-middleware';
import paramsMiddleware from '@tshio/redux-api-params-middleware';
import createEndpointMiddleware from '@tshio/redux-api-endpoint-middleware';

import injectTokenMiddleware from './injectTokenMiddleware';

const middlewareList = [
  thunk,
  injectTokenMiddleware,
  createEndpointMiddleware(),
  paramsMiddleware,
  apiMiddleware,
];

export default middlewareList;
