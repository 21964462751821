// eslint-disable-next-line import/no-extraneous-dependencies
import { takeLatest, call, put, select } from '@redux-saga/core/effects';
import * as moment from 'moment';
import { toast } from 'react-toastify';
import { configs } from '@whitelabel/core';
import { actions, selectors } from './slice';
import * as apis from './apis';

const { DOCUMENTS_UPDATE } = configs;

function* loadDocument(action) {
  try {
    const data = yield call(apis.fetchDocument, action.payload);
    yield put(actions.successLoading(data));
  } catch (e) {
    console.log(e);
    //  TODO: log error
  }
}

function* deleteDocument() {
  const { current } = yield select(selectors.selectState);
  try {
    yield call(apis.deleteDocument, current);
    yield put(actions.successDelete());
    yield put({ type: DOCUMENTS_UPDATE });
  } catch (e) {
    console.log(e);
    //  TODO: log error
  }
}

function* updateDocument(action) {
  const { Category, ...values } = action.payload;
  const { current } = yield select(selectors.selectState);

  const body = {
    ...values,
    CategoryId: Category.Id,
    Id: current,
    Cu: values.Cu
      ? {
          ...values.Cu,
          AnnoRiferimento: moment(`${values.Cu.AnnoRiferimento}-01-01`).format(
            'YYYY-MM-DDTHH:mm:ss',
          ),
        }
      : null,
  };
  try {
    yield call(apis.updateDocument, current, body);
    yield put({ type: DOCUMENTS_UPDATE });
    toast.success('Documento aggiornato');
  } catch (e) {
    console.log(e);
    //  TODO: log error
    toast.success("Errore nell'aggiornare il documento");
  }
  yield put(actions.updateEnd());
}

export default function* documentsSaga() {
  yield takeLatest(actions.startLoading.type, loadDocument);
  yield takeLatest(actions.deleteDocument.type, deleteDocument);
  yield takeLatest(actions.updateDocument.type, updateDocument);
}
