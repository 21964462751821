/* eslint-disable react/prop-types */
import { Button, Card, CardBody, Spinner } from 'reactstrap';
import React, { useEffect } from 'react';
import { Route, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SimpleTable, usePagination, formatMoney } from '@whitelabel/core';
import { format } from 'date-fns';
import { actions, selectors } from '../slice';
import SearchDocuments from '../SearchDocuments';

const COLUMNS = (dispatch, history) => [
  {
    Header: 'Nome',
    accessor: 'DocumentName',
  },
  {
    Header: 'Data',
    accessor: 'DocumentDate',
    Cell: ({ value }) => format(new Date(value), 'dd/MM/yyyy'),
  },
  {
    Header: 'Data di caricamento',
    accessor: 'CreatedAt',
    Cell: ({ value }) => format(new Date(value), 'dd/MM/yyyy'),
  },
  {
    Header: 'Importo',
    accessor: 'Amount',
    Cell: ({ value }) => formatMoney(value),
  },
  {
    Header: 'Azioni',
    Cell: ({ row }) => (
      <>
        <Button
          color="default"
          className="m-1 text-primary"
          onClick={() => history.push(`preview/${row.original.DocumentId}`)}
        >
          <i className="fas fa-eye" />
        </Button>
        {/* <Button */}
        {/*  color="default" */}
        {/*  className="m-1 text-danger" */}
        {/*  onClick={() => dispatch(actions.removePlaceholder(row.original.Id))} */}
        {/* > */}
        {/*  <i className="fas fa-unlink" /> */}
        {/* </Button> */}
      </>
    ),
  },
];

export default function DocumentManagement() {
  const history = useHistory();
  const { id, categoryId } = useParams();
  const state = useSelector(selectors.selectState);
  const activeCategory = useSelector(selectors.selectActiveCategory);
  const { loading, list, page, tot: totPages } = useSelector(selectors.selectDocuments);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.loadDocuments({ id, categoryId }));
  }, [id, categoryId]);

  const pagination = usePagination({
    totPages,
    loadPage: pageNum => dispatch(actions.documentsChangePage(pageNum)),
    page,
  });

  return (
    <>
      <div className="d-flex mt-4 align-items-center mb-2">
        <Button
          onClick={() => history.push(`/pratiche/${state.id}/`)}
          className="mr-2"
          color="default"
        >
          <i className="fas fa-arrow-left" />
        </Button>
        <h2 className="mb-0">
          {activeCategory.loading ? <Spinner /> : activeCategory.CategoryName} - pratica{' '}
          {state.data.Name}
        </h2>
        {/* <div className="ml-auto"> */}
        {/*  <Link to="associa/" className="btn btn-primary"> */}
        {/*    <i className="mr-1 fas fa-plus" /> */}
        {/*    Aggiungi */}
        {/*  </Link> */}
        {/* </div> */}
      </div>
      <Card>
        <CardBody className="p-0">
          <SimpleTable loading={loading} data={list} columns={COLUMNS(dispatch, history)} />
          {!loading && pagination}
        </CardBody>
      </Card>
      <Route component={SearchDocuments} path="/pratiche/:id/documenti/:categoryId/associa/" />
    </>
  );
}
