import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import DefaultLayout from '../components/DefaultLayout';
import { appLogout } from '../../../store/auth/actions';
// import { loadCategoriesAction } from '../../../store/categories/actions';
import { actions as macroActions } from '../../../store/macrocategories/reducer';

const enhance = compose(
  connect(
    () => ({
      users: 0,
      documents: 0,
      pendings: 0,
    }),
    dispatch => ({
      logout: appLogout(dispatch),
      // loadCategories: loadCategoriesAction(dispatch),
      loadMacro: () => dispatch(macroActions.loadStart()),
    }),
  ),
  lifecycle({
    componentDidMount() {
      this.props.loadMacro();
    },
  }),
);

export default enhance(DefaultLayout);
