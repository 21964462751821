import { compose, fromRenderProps, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';

import { ClientContext } from '@whitelabel/core';
import Page from '../components/Page';
import { actions } from '../slice';
import * as apis from '../apis';

const enhance = compose(
  fromRenderProps(ClientContext.Consumer, client => ({ client })),
  connect(
    state => ({
      data: state.documents.list,
      loading: state.documents.loading,
      selectedUser: state.documents.user,
      page: state.documents.page,
      totPages: state.documents.totPages,
      descending: state.documents.descending,
      showAll: state.documents.showAll,
      filtersKey: state.documents.key,
      orderBy: state.documents.orderBy,
      count: state.documents.count,
      amount: state.documents.amount,
      macroOptions: state.macrocategories.list,
      macro: state.documents.mainCategory,
      category: state.documents.category,
      catKey: state.documents.categoryKey,
      dateSelector: state.documents.dateSelector,
    }),
    dispatch => ({
      loadPage: val => dispatch(actions.startLoading(val)),
      reset: () => dispatch(actions.reset()),
      onChangeUser: data => {
        dispatch(actions.setUser(data));
      },
      onChangeDescending: val => dispatch(actions.setDescending(val)),
      onChangeOrderBy: val => dispatch(actions.setOrderBy(val.value)),
      onChangeShowAll: val => dispatch(actions.setShowAll(val)),
      setMacro: val => dispatch(actions.setMainCategory(val)),
      setCategory: val => {
        dispatch(actions.setCategory(val));
      },
      onDatesChange: ({ startDate, endDate }) => dispatch(actions.setDate({ startDate, endDate })),
    }),
  ),
  withState('focusedInput', 'setFocusedInput', null),
  withHandlers({
    loadCategories: ({ macro }) => async text => {
      const data = {};
      if (text) {
        data.query = text;
      }
      if (macro) {
        data.mainCategoryId = macro.Id;
      }
      const body = await apis.fetchCategories(data);
      return body.Categories;
    },
  }),
);

export default enhance(Page);
