export const LOAD_TOKEN = 'app/load-token';
export const LOAD_USER = 'app/load-user';
export const APP_LOGOUT = 'app/logout';

export const CATEGORIES_LOAD_START = 'categories/load/start';
export const CATEGORIES_LOAD_END = 'categories/load/end';
export const CATEGORIES_LOAD_FAIL = 'categories/load/fail';

export const MACROCATEGORIES_LOAD_END = 'macrocategories/load/success';
export const MACROCATEGORIES_LOAD_FAIL = 'macrocategories/load/fail';
export const MACROCATEGORIES_LOAD_START = 'macrocategories/load/start';
