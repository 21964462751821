import { compose, withStateHandlers } from 'recompose';
import { connect } from 'react-redux';

import Page from '../components/Page';
import { actions, selectors } from '../slice';

const enhance = compose(
  connect(
    state => ({
      data: state.procedures.list,
      loading: state.procedures.loading,
      selectedUser: state.procedures.user,
      dateSelector: state.procedures.dateSelector,
      status: state.procedures.status,
      page: state.procedures.page,
      totPages: state.procedures.totPages,
      descending: state.procedures.descending,
      showAll: state.procedures.showAll,
      filtersKey: state.procedures.key,
      orderBy: state.procedures.orderBy,
      canCreate: selectors.canCreate(state),
    }),
    dispatch => ({
      reset: () => dispatch(actions.reset()),
      loadPage: () => dispatch(actions.load()),
      onChangeUser: data => {
        dispatch(actions.setUser(data));
      },
      onChangeDescending: val => dispatch(actions.setDescending(val)),
      onChangeOrderBy: val => dispatch(actions.setOrderBy(val.value)),
      onChangeShowAll: val => dispatch(actions.setShowAll(val)),
    }),
  ),
  withStateHandlers(
    { catKey: 0 },
    {
      forceCatKey: state => () => ({ catKey: state.catKey + 1 }),
    },
  ),
);

export default enhance(Page);
