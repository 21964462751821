/* eslint-disable no-extend-native,no-bitwise */
if (!Array.prototype.fill) {
  Object.defineProperty(Array.prototype, 'fill', {
    value(value) {
      if (this == null) {
        throw new TypeError('this is null or not defined');
      }
      const O = Object(this);
      const len = O.length >>> 0;
      // eslint-disable-next-line prefer-rest-params
      const start = arguments[1];
      const relativeStart = start >> 0;
      let k = relativeStart < 0 ? Math.max(len + relativeStart, 0) : Math.min(relativeStart, len);
      // eslint-disable-next-line prefer-rest-params
      const end = arguments[2];
      const relativeEnd = end === undefined ? len : end >> 0;
      const finalValue =
        relativeEnd < 0 ? Math.max(len + relativeEnd, 0) : Math.min(relativeEnd, len);
      while (k < finalValue) {
        O[k] = value;
        // eslint-disable-next-line no-plusplus
        k++;
      }
      return O;
    },
  });
}
