import { compose, withHandlers } from 'recompose';
import { reduxForm } from 'redux-form';
import { toast } from 'react-toastify';
import { apiClient, APIError } from '@whitelabel/core';
import ForgotPasswordPage from '../components/ForgotPasswordPage';

const enhance = compose(
  withHandlers({
    onSubmit: ({ history }) => async ({ email }) => {
      try {
        await apiClient(`users/guest/recover/password?email=${email}`, { method: 'POST' });
        toast.success('È stata inviata un email per il reset della password');
        history.replace('/login');
      } catch (e) {
        if (e instanceof APIError) {
          toast.error(e.payload.Exception);
        } else {
          toast.error('Si è verificato un errore');
        }
      }
    },
  }),
  reduxForm({ form: 'pass-recover' }),
);

export default enhance(ForgotPasswordPage);
