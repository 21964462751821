import { CATEGORIES_LOAD_END, CATEGORIES_LOAD_FAIL, CATEGORIES_LOAD_START } from '../constants';

export const categoriesInitialState = {
  list: null,
};

function categoriesReducer(state = categoriesInitialState, action) {
  switch (action.type) {
    case CATEGORIES_LOAD_START:
      return {
        ...state,
        loading: true,
      };
    case CATEGORIES_LOAD_END:
      return {
        ...state,
        loading: false,
        list: action.payload.Data.Categories,
        currentPage: action.payload.Data.Page,
        totalPages: action.payload.Data.TotPages,
      };
    case CATEGORIES_LOAD_FAIL:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}

export default categoriesReducer;
