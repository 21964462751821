/* eslint-disable react/prop-types */
import React from 'react';
import { Card, CardBody, CardHeader, Col, Input, Label, Row, Spinner, Table } from 'reactstrap';
import moment from 'moment';
import 'react-vis/dist/style.css';
import Select from 'react-select';
import {
  RadialDocs,
  AggregateDocs,
  CardItem,
  Loading,
  Ellipsis,
  formatMoney,
} from '@whitelabel/core';
import { faClock, faFile, faHourglassStart } from '@fortawesome/free-solid-svg-icons';

const years = [];
let year = new Date().getFullYear();
while (year > new Date().getFullYear() - 30) {
  years.push({ value: year, label: year });
  year -= 1;
}

export default function DashboardPage({
  generic,
  docsData,
  docs,
  countData,
  amountData,
  selectUploaded,
  setSelectUpload,
  max,
  scale,
  setYear,
  categories,
  setCategory,
  loadingDocs,
  loadingAggr,
  macro,
  macroOptions,
  setMacro,
  resetKey,
  topCat,
  loadingTopCat,
}) {
  const registrationYear = moment(generic.RegistrationDate).year();
  return (
    <div className="mt-4">
      <Row>
        <Col md={6} xl={3}>
          <CardItem
            icon={faClock}
            title="Ultimo Accesso"
            text={moment(generic.LastAccess).format('DD/MM/YYYY HH:mm')}
          />
        </Col>
        <Col md={6} xl={3}>
          <CardItem
            icon={faHourglassStart}
            title="Data registrazione"
            text={moment(generic.RegistrationDate).format('DD/MM/YYYY HH:mm')}
          />
        </Col>
        <Col md={6} xl={3}>
          <CardItem icon={faFile} title="Documenti" text={docs.Total} />
        </Col>
      </Row>
      <Row>
        <Col lg={9} md={12}>
          <Card>
            <CardBody className="px-0">
              <div className="pr-5 w-100" style={{ height: '350px' }}>
                <AggregateDocs
                  max={max}
                  title={
                    <h3 className="ml-3 d-flex align-items-center">
                      Documenti {selectUploaded ? 'caricati per mese' : 'per mese'}
                      {loadingAggr && <Spinner className="ml-2 text-secondary" size="sm" />}
                    </h3>
                  }
                  scale={scale}
                  amountData={amountData}
                  countData={countData}
                />
              </div>
            </CardBody>
          </Card>
          <Row>
            <Col lg={4} md={12}>
              <Card>
                <CardHeader className="shadow h3 bg-primary rounded-bottom">Documenti</CardHeader>
                <CardBody>
                  <RadialDocs
                    data={docsData}
                    loading={loadingDocs}
                    total={docs.Total}
                    valid={docs.Valid}
                    pending={docs.Pending}
                    rejected={docs.Rejected}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col lg={8} md={12}>
              <Card>
                <CardHeader className="shadow h3 bg-primary rounded-bottom">
                  Top 5 Categorie
                </CardHeader>
                <CardBody>
                  <Table responsive="md">
                    <thead className="font-weight-bold">
                      <td className="text-center">Categoria</td>
                      <td className="text-center">Numero Documenti</td>
                      <td className="text-center">Importo totale</td>
                    </thead>
                    <tbody>
                      {loadingTopCat && (
                        <tr>
                          <td colSpan={3}>
                            <Loading />
                          </td>
                        </tr>
                      )}
                      {!loadingTopCat &&
                        topCat.map(t => (
                          <tr key={t.CategoryId}>
                            <Ellipsis className="text-center">{t.CategoryName}</Ellipsis>
                            <td className="text-center">{t.Count}</td>
                            <Ellipsis className="text-center">{formatMoney(t.Amount)}</Ellipsis>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col md={12} lg={3}>
          <Card>
            <CardBody>
              <div className="mb-2">
                <Label>Raggruppa per</Label>
                <div className="ml-4">
                  <Label className="d-block mb-1">
                    <Input
                      type="radio"
                      name="radio1"
                      checked={selectUploaded}
                      onChange={() => setSelectUpload(true)}
                    />{' '}
                    Data Caricamento
                  </Label>
                  <Label className="d-block mb-1">
                    <Input
                      type="radio"
                      name="radio1"
                      checked={!selectUploaded}
                      onChange={() => setSelectUpload(false)}
                    />{' '}
                    Data Documento
                  </Label>
                </div>
              </div>
              <div className="mb-2">
                <Label>Anno</Label>
                <Select
                  options={years.filter(o => o.value >= registrationYear)}
                  onChange={({ value }) => setYear(value)}
                  placeholder="Seleziona l'anno"
                />
              </div>
              <div className="mb-2">
                <Label>Macro Categoria</Label>
                <Input
                  value={macro}
                  key={macro}
                  type="select"
                  placeholder=""
                  onChange={e => setMacro(e.target.value)}
                >
                  <option value={null}>Seleziona Macro categoria</option>
                  {macroOptions &&
                    macroOptions.map(m => (
                      <option key={m.Id} value={m.Id}>
                        {m.Name}
                      </option>
                    ))}
                </Input>
              </div>
              <div className="mb-2">
                <Label>Categoria</Label>
                <Select
                  getOptionLabel={opt => opt.Name}
                  getOptionValue={opt => opt.Id}
                  onChange={setCategory}
                  isClearable
                  placeholder="Seleziona la categoria"
                  key={resetKey}
                  options={categories}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
