/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, UncontrolledTooltip, Button } from 'reactstrap';
import { format } from 'date-fns';
import {
  SimpleTable,
  usePagination,
  utils,
  configs,
  ButtonTooltip,
  BadgeIcon,
} from '@whitelabel/core';
import * as classnames from 'classnames';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { STATUS, STATUS_OPTIONS } from '../../Procedure/config';
import { actions } from '../slice';

const { activeFilterStyle } = utils;
const { ORDER_BY_PROCEDURES } = configs;

const columns = [
  {
    Header: 'Nome',
    accessor: 'Name',
  },
  {
    Header: 'Stato',
    accessor: 'Status',
    Cell: ({ value }) => STATUS[value],
  },
  {
    Header: 'Data Creazione',
    accessor: 'CreatedAt',
    Cell: ({ value }) => format(new Date(value), 'dd/MM/yyyy HH:mm'),
  },
  {
    Header: 'Dettagli',
    accessor: 'Id',
    Cell: ({ value, row: { original } }) => {
      const Badge = BadgeIcon(
        original.UnreadedMessages,
        <FontAwesomeIcon icon={faEye} size="lg" />,
      );
      return (
        <Button tag={Link} color="primary" to={`/pratiche/${value}/`} className="m-1">
          <Badge className="view-notification-badge" />
        </Button>
      );
    },
  },
];

export default function Page({
  data,
  loading,
  page,
  totPages,
  loadPage,
  dateSelector,
  onChangeDescending,
  onChangeOrderBy,
  descending,
  status,
  canCreate,
}) {
  const pagination = usePagination({
    totPages,
    loadPage,
    page,
  });

  const [focusedInput, setFocusedInput] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    loadPage();
  }, []);

  return (
    <>
      <div className="mt-3 d-flex">
        <div className="d-flex align-items-center flex-wrap w-100">
          <Select
            options={STATUS_OPTIONS}
            placeholder="Stato"
            isClearable
            className="mr-1 mb-1"
            onChange={d => dispatch(actions.setStatus(d))}
            value={status}
            styles={activeFilterStyle(status, { input: () => ({ minWidth: '4rem' }) })}
          />
          <div
            className={classnames({
              'active-filter-date': dateSelector.dateStart && dateSelector.dateStart,
            })}
          >
            <DateRangePicker
              startDate={dateSelector.dateStart && moment(dateSelector.dateStart, 'YYYY-MM-DD')}
              endDate={dateSelector.dateEnd && moment(dateSelector.dateEnd, 'YYYY-MM-DD')}
              endDateId="endDate"
              startDateId="startDate"
              focusedInput={focusedInput}
              onDatesChange={({ endDate, startDate }) =>
                dispatch(
                  actions.setDateSelector({
                    dateStart: startDate && moment(startDate).format('YYYY-MM-DD'),
                    dateEnd: endDate && moment(endDate).format('YYYY-MM-DD'),
                  }),
                )
              }
              onFocusChange={setFocusedInput}
              isOutsideRange={() => false}
              startDatePlaceholderText="Inizio"
              endDatePlaceholderText="Fine"
              noBorder
              showClearDates
            />
          </div>
          <ButtonTooltip
            tag={Link}
            to="crea/"
            id="create-procedure"
            disabled={!canCreate}
            tooltip={{ children: 'Crea una nuova pratica' }}
            className="ml-auto shadow border-0 d-block btn btn-primary"
          >
            <i className="fas fa-plus" /> Nuova Pratica
          </ButtonTooltip>
        </div>
      </div>
      <Card className="mt-3 shadow-lg border-0">
        <CardHeader className="shadow bg-primary-light rounded-bottom d-flex">
          <div className="h3 text-white">Pratiche</div>
          <div className="d-flex align-items-center ml-auto text-default">
            <Select
              placeholder="Ordina per"
              options={ORDER_BY_PROCEDURES}
              onChange={onChangeOrderBy}
              styles={{ input: () => ({ minWidth: '10rem' }) }}
            />
            <UncontrolledTooltip target="order" placement="top">
              {descending ? 'Discentente' : 'Ascendente'}
            </UncontrolledTooltip>
            <Button
              id="order"
              className="shadow ml-1 text-primary bg-white border-0"
              onClick={() => onChangeDescending(!descending)}
            >
              <i
                className={classnames('fas', {
                  'fa-caret-down': descending,
                  'fa-caret-up': !descending,
                })}
              />
            </Button>
          </div>
        </CardHeader>
        <CardBody className="p-0">
          <SimpleTable
            loading={loading}
            data={data || []}
            columns={columns}
            emptyText="Non ci sono pratiche"
          />
          {!loading && pagination}
        </CardBody>
      </Card>
    </>
  );
}

Page.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      UserName: PropTypes.string.isRequired,
      Name: PropTypes.string.isRequired,
      CategoryName: PropTypes.string.isRequired,
      Amount: PropTypes.number.isRequired,
    }),
  ),
  loading: PropTypes.bool.isRequired,
};

Page.defaultProps = {
  data: [],
};
