import { Button, Card, CardBody, Input, InputGroup, InputGroupAddon, Form } from 'reactstrap';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Loading } from '@whitelabel/core';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { actions, selectors } from '../slice';

export default function Chat() {
  const { loading, list: messages } = useSelector(selectors.selectMessages);
  const [message, setState] = useState('');
  const dispatch = useDispatch();
  const ref = useRef();

  const send = e => {
    e.preventDefault();
    dispatch(actions.sendMessage(message));
    setState('');
  };

  let content = null;
  if (loading) {
    content = <Loading />;
  } else if (messages.length > 0) {
    content = messages
      .slice()
      .reverse()
      .map(m => (
        <div
          key={m.Id}
          id={`message-${m.Id}`}
          className={classNames({
            'user-message bg-gradient text-white': m.UserCreatorId,
            'admin-message ml-auto': m.AdminCreatorId,
          })}
        >
          <div className="arrow">
            <div className="inner" />
          </div>
          <div className="message-body">
            {m.Message}
            <span className="small text-muted d-block text-right">
              {moment(m.CreatedAt).format('DD/MM/YYYY HH:mm')}
            </span>
          </div>
        </div>
      ));
  } else {
    content = <h5 className="my-5 mx-auto text-center">Non ci sono messaggi</h5>;
  }

  useEffect(() => {
    if (!loading) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [loading]);

  return (
    <>
      <h5>Messaggi</h5>
      <Card>
        <CardBody className="p-0">
          <div
            ref={ref}
            className="px-1 pb-2"
            id="messages"
            style={{ minHeight: '20vh', maxHeight: '60vh', overflowY: 'auto' }}
          >
            {content}
          </div>
          <div>
            <Form onSubmit={send}>
              <InputGroup>
                <Input
                  onChange={e => setState(e.target.value)}
                  value={message}
                  placeholder="Inserisci il messaggio"
                  className="border-bottom-0 border-left-0 border-right-0 rounded-0"
                />
                <InputGroupAddon addonType="append">
                  <Button className="rounded-0" type="submit">
                    <i className="fa fa-paper-plane text-white" />
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </Form>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
