import { compose, withProps } from 'recompose';
import { change, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import moment from 'moment';
import { CU } from '@whitelabel/core';
import DocumentUpdateForm from './DocumentUpdateForm';
import { NOTES_OPTIONS, NOTES_TYPES } from '../../constants';

const { CU_CATEGORIES } = CU;

const selector = formValueSelector('updateDocument');

const enhance = compose(
  connect(
    state => ({
      notes: selector(state, 'Notes'),
      macro: state.macrocategories.list,
      state,
    }),
    dispatch => ({
      resetCategory: () => dispatch(change('updateDocument', 'Category', '')),
    }),
  ),
  withProps(({ initialValues: document, state, getCategoriesList }) => ({
    form: 'updateDocument',
    initialValues: {
      ...document,
      Date: document.Date,
      Amount: document.Amount,
      Notes:
        document.Notes && !NOTES_OPTIONS.includes(document.Notes)
          ? NOTES_TYPES.ALTRO
          : document.Notes || '',
      OtherNotes: document.Notes && !NOTES_OPTIONS.includes(document.Notes) ? document.Notes : '',
      Category: { Name: document.CategoryName, Id: document.CategoryId },
      MainCategoryId: document.MainCategoryId,
      VATAmount: document.VATAmount,
      RFP: document.RFP,
      Cu: document.Cu
        ? {
            ...document.Cu,
            AnnoRiferimento: moment(document.Cu.AnnoRiferimento).year(),
          }
        : null,
    },
    currentMain:
      state.form.updateDocument &&
      state.form.updateDocument.values &&
      state.form.updateDocument.values.MainCategoryId,
    current: state.form.updateDocument && state.form.updateDocument.values,
    isCU:
      state.form.updateDocument && state.form.updateDocument.values
        ? CU_CATEGORIES.includes(state.form.updateDocument.values.Category.Id)
        : CU_CATEGORIES.includes(document.CategoryId),
    loadCategories: async input => {
      let mainCategoryId = null;
      if (state.form && state.form.updateDocument) {
        const { MainCategoryId } = state.form.updateDocument.values;
        mainCategoryId = MainCategoryId;
      } else if (document.MainCategoryId) {
        mainCategoryId = document.MainCategoryId;
      }
      return getCategoriesList(input, mainCategoryId);
    },
  })),
  reduxForm({
    enableReinitialize: true,
  }),
);

export default enhance(DocumentUpdateForm);
