import React from 'react';

import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import Single from './Single';
import { name, reducer } from './slice';
import saga from './sagas';

export default function Wrapper() {
  useInjectReducer({
    key: name,
    reducer,
  });

  useInjectSaga({
    key: name,
    saga,
  });

  return <Single />;
}
