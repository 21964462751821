import PropTypes from 'prop-types';
import React from 'react';
import CardBody from 'reactstrap/es/CardBody';
import Card from 'reactstrap/es/Card';
import Label from 'reactstrap/es/Label';
import FormGroup from 'reactstrap/es/FormGroup';
import Input from 'reactstrap/es/Input';
import Form from 'reactstrap/es/Form';
import Button from 'reactstrap/es/Button';
import Col from 'reactstrap/es/Col';
import PasswordMask from 'react-password-mask';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import logo from '../../../assets/logo.png';
import logoFull from '../../../assets/logo-full.png';

export default function LoginPage({
  state: { email, password, loading = false },
  setEmail,
  setPassword,
  onSubmit,
}) {
  return (
    <div
      className="d-flex justify-content-center align-items-center bg-login-background"
      style={{ height: '100vh' }}
    >
      <Col md={4}>
        <Card className="border-0">
          <CardBody className="px-5">
            <div className="d-flex justify-content-center align-items-center">
              <img src={logo} alt="logo" className="mr-2 my-2 mw-100" />
              <img src={logoFull} alt="logo-full" className="mw-100" />
            </div>
            <Form className="md-form" onSubmit={onSubmit}>
              <FormGroup className="my-4">
                <Label className="d-none">E-mail</Label>
                <Input
                  placeholder="E-mail"
                  type="email"
                  required
                  onChange={setEmail}
                  value={email}
                />
              </FormGroup>
              <FormGroup className="my-4">
                <Label className="d-none">Password</Label>
                <PasswordMask
                  placeholder="Password"
                  required
                  onChange={setPassword}
                  value={password}
                  showButtonContent={<FontAwesomeIcon icon={faEye} />}
                  hideButtonContent={<FontAwesomeIcon icon={faEyeSlash} />}
                  buttonStyles={{ background: 'transparent', color: 'var(--primary)' }}
                />
                <p className="text-right">
                  <small>
                    <Link to="/forgot-password">Password dimenticata?</Link>
                  </small>
                </p>
              </FormGroup>
              <FormGroup className="d-flex justify-content-center mt-5">
                <Button color="primary" className="btn-round" type="submit">
                  Accedi {loading ? <i className="fas fa-spinner fa-spin" /> : null}
                </Button>
              </FormGroup>
            </Form>
            <div className="d-flex justify-content-center">
              <Link to="/registration">Registrati</Link>
            </div>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
}

LoginPage.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  setEmail: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired,
  state: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
    tfa: PropTypes.string,
    showTFA: PropTypes.bool,
    loading: PropTypes.bool,
  }).isRequired,
};
