import { compose, fromRenderProps, withHandlers, withState } from 'recompose';
import { toast } from 'react-toastify';
import { ClientContext } from '@whitelabel/core';
import fill from 'lodash/fill';
import { connect } from 'react-redux';
import UploadDocument from '../components/UploadDocument';

const enhance = compose(
  fromRenderProps(ClientContext.Consumer, client => ({ client })),
  connect(
    ({ user }) => ({
      userId: user.id,
    }),
    () => ({}),
  ),
  withState('key', 'setKey', 0),
  withState('files', 'setFiles', []),
  withState('loading', 'setLoading', false),
  withState('uploaded', 'setUploadEnd', false),
  withState('filesStatus', 'setFilesStatus', []),
  withHandlers({
    handleDrop: ({ files, setFiles }) => (accepted, rejected) => {
      if (accepted.length > 0) {
        setFiles([...files, ...accepted]);
      }
      if (rejected.length > 0) {
        rejected.forEach(f => {
          console.log(f);
          toast.error(`Non è possibile caricare ${f.file.path}: ${f.errors[0].message}`);
        });
      }
    },
    removeFile: ({ files, setFiles }) => index => {
      setFiles(files.filter((f, i) => index !== i));
    },
    upload: ({ setLoading, setUploadEnd, client, files, userId, setFilesStatus }) => async () => {
      setLoading(true);

      const statuses = new Array(files.length);
      fill(statuses, 'Successo');

      const promises = files.map(async (f, i) => {
        try {
          const extension = f.path.split('.').pop();
          const response = await client(`documents/getuploadurl?userId=${userId}`);
          const url = new URL(response);
          url.search = '';

          await fetch(response, {
            method: 'PUT',
            headers: {
              'Content-Type': f.type,
            },
            body: f,
          });

          await client(`documents/createfromweb`, {
            method: 'POST',
            body: {
              S3FilePath: url.toString(),
              OwnerId: userId,
              Extension: extension,
            },
          });
        } catch (e) {
          console.log(e);
          statuses[i] = 'Errore';
        }
      });

      await Promise.all(promises);
      setFilesStatus(statuses);
      setLoading(false);
      setUploadEnd(true);
    },
    reset: ({ setLoading, setUploadEnd, setFiles, key, setKey }) => () => {
      setLoading(false);
      setUploadEnd(false);
      setFiles([]);
      setKey(key);
    },
  }),
);

export default enhance(UploadDocument);
