import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createInjectorsEnhancer } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';
import middlewares from './middlewares';
import createRootReducer from './reducer';
import appSaga from './sagas';

const hydratate = (initialValue = {}) => {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

  const { run: runSaga } = sagaMiddleware;

  const enhancers = [
    createInjectorsEnhancer({
      createReducer: createRootReducer,
      runSaga,
    }),
  ];

  const store = configureStore({
    reducer: createRootReducer(),
    preloadedState: { ...initialValue },
    middleware: [...getDefaultMiddleware(), ...middlewares, sagaMiddleware],
    devTools: true,
    enhancers,
  });

  runSaga(appSaga);

  return store;
};

export default hydratate;
